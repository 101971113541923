import React from 'react'
import axios from 'axios'
import { Modal, Button } from 'react-bootstrap'
import ReactTooltip from 'react-tooltip'
import firstBy from 'thenby'
import moment from 'moment'
import Clipboard from 'clipboard'
import { Redirect } from 'react-router'
import { ShareButtons } from 'react-share'
import * as Sentry from '@sentry/browser'
import $ from 'jquery'
import _ from 'underscore'

// Components
import Score from '../Score'
import Loading from '../Loading'
import PageWrapper from '../PageWrapper'
import ScoreMobile from '../ScoreMobile'
import MediaQuery from 'react-responsive'
import WeeksControls from '../WeeksControls'
import QuinielaLobby from '../QuinielaLobby'
import InviteFriendsPlay from '../InviteFriendsPlay'
import CancelRegister from '../CancelRegister/CancelRegister'
import InvitarAmigos from '../InvitarAmigos/InvitarAmigos'
import { QuinielaDataProvider } from '../../context/QuinielaDataContext'
import { UserDataContextConsumer } from '../../context/UserDataContext'

// Utils
import Utils from '../../utils/quinielasUtils'
import utils from '../../utils/utils'
import branchUtils from '../../utils/branchUtils'
import API from '../../utils/api'
import gaUtils from '../GAWrapper'
import SubscriptionRxs from '../../utils/subscriptions'

// Assets
import regaloIcon from '../../assets/images/regalo-50.png'
import iconFriends from '../../assets/images/icon-friends.png'
import iconCopy from '../../assets/images/icon-copy.png'
import invitaAmigos from '../../assets/images/invita-amigos-mundial.jpg'

const TIME_CHECK_AFTER_WS_CHANGE = 1000 * 60 * 5 // 5 min

const history = require('history').createBrowserHistory()

let clipboardCode

const {
  FacebookShareButton,
  TwitterShareButton,
} = ShareButtons;

const PanelShowEntryGift = ({ show, entryGiftPopUp, closePanel, title }) => {
  if (!entryGiftPopUp) {
    return null;
  }
  const url = `/quiniela/quiniela/${entryGiftPopUp.entry_gift.pool_id}/${entryGiftPopUp.entry_gift.id}`;
  return (
    <Modal
      dialogClassName="invitrAmigosModalDialog col-xs-10 col-md-5"
      className="invitarAmigosModal"
      backdropClassName="invitarAmigosBackdrop"
      show={show}
      oHide={closePanel}
    >
      <div className="panel-abonar panel-pickwin">
        <div id="panelTitle" className="panel-abonar-titulo">
          <span className="flex-row row-center">
            <img src={regaloIcon} className="pool-icons" />
            {title}
          </span>
          <button className="cerrar-panel" onClick={closePanel}>
            <div className="btn-icon-close">
              <i className="ion-ios-close-empty" />
            </div>
          </button>
        </div>
        <div className="flex-row">
          <a className="flex-col " href={url}>
            <img
              onClick={closePanel}
              src="https://storage.googleapis.com/pw-resources/banners/popUpEntryGift.jpg"
              className="flex-row img-reponsive"
            />
          </a>
        </div>
        <div className="flex-row row-center responsive-sm">
          <div className="flex-col col-50 col-center">
            <Button className="btn btn-info flex-row text-center" onClick={closePanel}>
              <span className="flex-col text-center"> DESPUÉS</span>
            </Button>
          </div>
          <a className="flex-col col-50 col-center" href={url}>
            <Button
              className="btn btn-pick success text-center callToAction flex-row"
              onClick={closePanel}
            >
              <span className="flex-col text-center"> Ir a la Quiniela </span>
            </Button>
          </a>
        </div>
      </div>
    </Modal>
  );
};

const PanelShowPopUpDeposit = ({ show, closePanel, title }) => {
  //const url = `http://pickwin.test:8080/usuario/abonar`;
  return (
    <Modal
      dialogClassName="invitrAmigosModalDialog col-xs-10 col-md-5"
      className="invitarAmigosModal"
      backdropClassName="invitarAmigosBackdrop"
      show={show}
      oHide={closePanel}
    >
      <div className="panel-abonar panel-pickwin">
        <div id="panelTitle" className="panel-abonar-titulo">
          <span className="flex-row row-center">
            <img src={regaloIcon} className="pool-icons" />
            {title}
          </span>
          <button className="cerrar-panel" onClick={closePanel}>
            <div className="btn-icon-close">
              <i className="ion-ios-close-empty" />
            </div>
          </button>
        </div>
        <div className="flex-row">
          <div className="flex-col ">
            <img
              src="https://storage.googleapis.com/pw-resources/banners/popUpDeposito.jpg"
              className="flex-row img-reponsive"
            />
          </div>
        </div>
        <div className="flex-row row-center responsive-sm">
          <div className="flex-col col-33 col-offset-33 col-center">
            <Button
              className="btn btn-pick  success callToAction flex-row text-center"
              onClick={closePanel}
            >
              <span className="flex-col text-center"> Entendido </span>
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export const PanelInvitarAmigos = ({
  title,
  pool,
  openInvitePanel,
  closePanel,
  branchLink,
  whatsAppInvitation,
}) => {
  let isMundial = false;
  let inviteText = '';
  if (pool.id === 12408) {
    isMundial = true;
    title = 'INVITA A TUS AMIGOS Y GANA ENTRADAS AL MUNDIAL PERFECTO';
    inviteText =
      'YO ESTOY JUGANDO LA QUINIELA DEL MUNDIAL TE INVITO A JUGAR LA MEJOR QUINIELA DEL MUNDIAL CON BOLSA DE $3,000,000';
  }

  const userData = utils.getUserInfo();
  return (
    <div className="panel-abonar">
      <div id="panelTitle" className="panel-abonar-titulo">
        <span>
          <img src={iconFriends} style={{marginRight: '1rem', width: '25px', height: 'auto'}}/>
          {title}
        </span>
        <button className="cerrar-panel" onClick={closePanel}>
          <div className="btn-icon-close">
            <i className="ion-ios-close-empty" />
          </div>
        </button>
      </div>
      <div className="col-xs-12">
        <div className="friends-play-container">
          <InviteFriendsPlay />
        </div>
      </div>
      <div className="col-xs-12">
        <div className="detalles-privadas-compartir-container">
          {pool.id === 12408 ? (
            <img src={invitaAmigos} className="img-responsive" />
          ) : (
              <div className="col-xs-12">
                <div className="panel-invite-title text-center">
                  Comparte este link con tus amigos
              </div>
                <div className="flex-row row-center flex-container justify">
                  <div className="codigo-amigo flex-col">
                    <input
                      id="copiar-clipboard"
                      value={
                        branchLink !== undefined && branchLink !== null
                          ? branchLink
                          : `https://www.pickwin.mx/lobby/quinielaPop/${pool.slug}/${pool.id}?referer=${userData.referalCode}`
                      }
                      onChange={() => {}}
                    />
                  </div>
                  <div className="copiar-button-codigo flex-col col-15">
                    <ReactTooltip id="copiar-tool" place="bottom" type="light" effect="solid">
                      {' '}
                    Copia el enlace y compártelo
                  </ReactTooltip>
                    <button
                      data-tip
                      data-for="copiar-tool"
                      className="copiar-btn"
                      data-clipboard-target="#copiar-clipboard"
                    >
                      {/* <i className="ion-ios-copy " /> */}
                      <img src={iconCopy} />
                      <span className="visible-md">Copiar</span>
                    </button>
                  </div>
                </div>
              </div>
            )}

          <div className="btn-group inviteToPlay flex-row">
            <FacebookShareButton
              quote={
                !isMundial
                  ? `Participa en la quiniela ${pool.name_replaced} visitando este link `
                  : inviteText
              }
              hashtag={'#Pickwin #registrate #JalaTusPicks'}
              url={
                branchLink !== undefined
                  ? branchLink
                  : `https://www.pickwin.mx/quiniela/${pool.slug}/${pool.id}?referer=${userData.referalCode}`
              }
            >
              <Button className="fb-btn">
                {' '}
                Por Facebook <i className="ion-social-facebook" />
              </Button>
            </FacebookShareButton>
            <TwitterShareButton
              title={
                !isMundial
                  ? `Participa en la quiniela ${pool.name_replaced} visitando este link `
                  : inviteText
              }
              url={
                branchLink !== undefined
                  ? branchLink
                  : `https://www.pickwin.mx/quiniela/${pool.slug}/${pool.id}?referer=${userData.referalCode}`
              }
              hasthag={'#Pickwin #registrate #JalaTusPicks'}
            >
              <Button className="twitter-btn">
                {' '}
                Por Twitter <i className="ion-social-twitter" />
              </Button>
            </TwitterShareButton>
            <div className="SocialMediaShareButton">
              <Button className="mail-btn" onClick={openInvitePanel}>
                {' '}
                Por Mail <i className="ion-email" />
              </Button>
            </div>
            <div className="SocialMediaShareButton">
              <Button className="whatsapp-btn" onClick={whatsAppInvitation}>
                {' '}
                Por WhatsApp <i className="ion-social-whatsapp" />
              </Button>
            </div>
          </div>
          {pool.id === 12408 ? (
            <div className="flex-row">
              <div className="flex-col text-center">
                <small>
                  <strong>
                    *Por cada amigo que se registre a la quiniela de 3 millones, te regalamos una
                    entrada para jugar el mundial Perfecto
                  </strong>
                </small>
              </div>
            </div>
          ) : null}
        </div>
        <div className="btn-wrap">
          <Button className="btn-rojo gracias" onClick={closePanel}>
            No, gracias
          </Button>
        </div>
      </div>
    </div>
  );
};

class Quinielas extends React.Component {

  constructor(props) {

    super(props);
    this.state = {
      activeSort: {
        posiciones: false,
        jugadores: false,
        aciertos: false,
        premios: false,
      },
      branchLink: null,
      allEntries: null,
      entries: null,
      createdPrivate: null,
      fixtures: [],
      parley: [],
      parleyDeadlines: [],
      cambiarEntradas: null,
      changedStatus: false,
      liveClosedFixtures: null,
      liveFixturesData: Date.now(),
      livePropsData: null,
      items: [],
      invitarAmigosMail: false,
      loaded: false,
      loadedPicks: false,
      logged: null,
      mounted: false,
      nextPicks: null,
      nextProps: null,
      ticketsAvailable: null,
      // my_pools                 : null,
      // pools                    : null,
      poolWeeks: null,
      poolInfo: null,
      poolWeekSelected: null,
      popUpNewUsersStatus: false,
      popUpNewUsers: null,
      quinielaID: undefined,
      redirectMisQuinielas: null,
      redirectToLobby: null,
      redirectToSame: null,
      redirectLobbyWithEntry: false,
      redirectToMainLobby: false,
      searchNick: null,
      selectedEntry: null,
      selectedEntryData: null,
      selectedWeekFixtures: [],
      sortChanged: false,
      emptyList: false,
      standings: undefined,
      submittingSearch: false,
      showEntryGift: false,
      showPopUpDeposit: false,
      entryGiftPopUp: null,
      defaultStandings: undefined,
      showPanelCancel: false,
      showPanelFirstRegister: false,
      showPanelRecomendation: false,
      showPanelReRegister: false,
      showInvite: false,
      showInvitePanel: false,
      standGroups: null,
      userData: null,
      updateCurrentEntry: false,
      updatedStandings: false,
      withRegister: false,
    };

    this.queryParams = {
      page: 1,
      limit: 100,
      nick: '',
      sort: [],
      uid: utils.isLogged() ? utils.getUserInfo().id : undefined,
    };

    this.queryParams.order = 'ASC';

    this.changeSelectedEntry = this.changeSelectedEntry.bind(this);
    this.changeSelectedWeek = this.changeSelectedWeek.bind(this);
    this.refreshAfterGuardar = this.refreshAfterGuardar.bind(this);
    this.refreshAfterRecomendation = this.refreshAfterRecomendation.bind(this);
    this.updateClosedQuiniela = this.updateClosedQuiniela.bind(this);
    this.updateFixtures = this.updateFixtures.bind(this);
    this.updatePanelCancelState = this.updatePanelCancelState.bind(this);
    this.updateStartedFixture = this.updateStartedFixture.bind(this);

    this.updateStandingsFixturePoints = Utils.updateStandingsFixturePoints.bind(this);
    this.updateEntryWithLiveData = Utils.updateEntryWithLiveData.bind(this);
    this.updateStandingsPropsPoints = Utils.updateStandingsPropsPoints.bind(this);
    this.updateStandingsPosition = Utils.updateStandingsPosition.bind(this);
    this.updateStandingsAganar = Utils.updateStandingsAganar.bind(this);
    this.loadNextPicksStandings = Utils.loadNextPicksStandings.bind(this);
    this.loadNextPicksPropsStandings = Utils.loadNextPicksPropsStandings.bind(this);

    this.changeStatusPool = this.changeStatusPool.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleClickFirstRegister = this.handleClickFirstRegister.bind(this);
    this.handleClickRecomendation = this.handleClickRecomendation.bind(this);
    this.handleClickReRegister = this.handleClickReRegister.bind(this);
    this.handleUpdateReset = this.handleUpdateReset.bind(this);
    this.quinielaLive = this.quinielaLive.bind(this);
    this.redirectAfterCancel = this.redirectAfterCancel.bind(this);
    this.redirectAfterCancelCurrentEntry = this.redirectAfterCancelCurrentEntry.bind(this);
    this.redirectToLobby = this.redirectToLobby.bind(this);
    this.refreshAfterCancel = this.refreshAfterCancel.bind(this);
    this.resetPosiciones = this.resetPosiciones.bind(this);
    this.sortPositionList = this.sortPositionList.bind(this);
    this.updateChildComponents = this.updateChildComponents.bind(this);
    this.updatePanelFirstRegister = this.updatePanelFirstRegister.bind(this);
    this.updatePanelRecomendation = this.updatePanelRecomendation.bind(this);
    this.updatePanelReRegister = this.updatePanelReRegister.bind(this);
    this.updateUserInfo = this.updateUserInfo.bind(this);

    this.reiniciarCambioDeEntrada = this.reiniciarCambioDeEntrada.bind(this);

    this.updateEntriesParent = (updatedEntry, responseEntry) => {
      let temporalEntries = this.state.allEntries;

      console.log('Update this  entries ', responseEntry);
      // console.log( '---updatedEntry---');
      // console.table( updatedEntry.picks          );
      // console.log( '-------------' );
      // console.table( temporalEntries.entry.picks );

      if (!temporalEntries.entry) {
        return;
      }

      _.each(updatedEntry.picks, (newPick) => {
        let idx = temporalEntries.entry.picks.findIndex(
          (_pick) => _pick.fixture_id === newPick.fixture_id
        );
        if (idx >= 0) {
          temporalEntries.entry.picks[idx].id = newPick.id;
          temporalEntries.entry.picks[idx].pick = newPick.pick;
          temporalEntries.entry.picks[idx].entry_id = newPick.entry_id;
          temporalEntries.entry.picks[idx].week_id = newPick.week_id;
          temporalEntries.entry.picks[idx].user_id = newPick.user_id;
        } else {
          temporalEntries.entry.picks.push(newPick);
        }
      });

      _.each(updatedEntry.picks_props, (newPickProp) => {
        let idx = temporalEntries.entry.picks_props.findIndex(
          (_pickProp) => _pickProp.prop_id === newPickProp.prop_id
        );
        if (idx >= 0) {
          temporalEntries.entry.picks_props[idx].id = newPickProp.id;
          temporalEntries.entry.picks_props[idx].entry_id = newPickProp.entry_id;
          temporalEntries.entry.picks_props[idx].pick = newPickProp.pick;
          temporalEntries.entry.picks_props[idx].prop_id = newPickProp.prop_id;
        } else {
          temporalEntries.entry.picks_props.push(newPickProp);
        }
      });

      _.each(updatedEntry.user_tiebreakers, (newUserTiebreaker) => {
        if (temporalEntries.entry.user_tiebreakers) {
          let idx = temporalEntries.entry.user_tiebreakers.findIndex(
            (_userTiebreaker) => _userTiebreaker.tiebreaker_id === newUserTiebreaker.tiebreaker_id
          );
          if (idx >= 0) {
            temporalEntries.entry.user_tiebreakers[idx].value = newUserTiebreaker.value;
          } else {
            temporalEntries.entry.user_tiebreakers.push(newUserTiebreaker);
          }
        }
      });

      // console.table( temporalEntries.entry.picks );
      // console.table( updatedEntry.picks );

      // temporalEntries.entry.picks            = Object.values(updatedEntry.picks)
      //   .filter((_pick) => _pick.pick  !==  null );

      // temporalEntries.entry.picks_props      = Object.values(updatedEntry.picks_props)
      //   .filter((_pick_prop) => _pick_prop.pick !==  null );

      // temporalEntries.entry.user_tiebreakers = Object.values( updatedEntry.user_tiebreakers )
      //   .filter( (_user_tiebreaker) => _user_tiebreaker.value !==  null );

      // temporalEntries.entry.picks = Object.values( updatedEntry.picks )
      //   .filter((_pick) => { _pick.pick !== null } );
      // temporalEntries.entry.picks_props = Object.values( updatedEntry.picks_props )
      //   .filter((_pick_prop) => _pick_prop.pick !== null);
      /**
       * Assign picks
       */
      // temporalEntries.entry.picks                     = [...temporalEntries.entry.picks, ...updatedEntry.picks]
      // if(updatedEntry.picks && temporalEntries.entry.picks ) {
      //   _.forEach( updatedEntry.picks, ( pick , indexPick ) => {
      //     if( temporalEntries.entry.picks.length !== 0 ){
      //       temporalEntries.entry.picks.push( pick );
      //     }
      //   })
      // }
      /**
       * Assign  pick_props
       */
      // temporalEntries.entry.picks_props               = [...temporalEntries.entry.picks_props, ...updatedEntry.picks_props]
      //  if( updatedEntry.picks_props && temporalEntries.entry.picks_props ){
      //    // console.log('assign props');
      //    _.forEach( updatedEntry.picks_props, ( pick , indexPick ) => {
      //      if(temporalEntries.entry.picks_props.length !== 0){
      //        temporalEntries.entry.picks_props.push( pick );
      //      }
      //    })
      //  }
      /**
       * Assign  pick_props
       */
      // temporalEntries.entry.user_tiebreakers          = [...temporalEntries.entry.user_tiebreakers, ...updatedEntry.user_tiebreakers]
      //  if(updatedEntry.user_tiebreakers && temporalEntries.entry.user_tiebreakers ){
      //    _.forEach( updatedEntry.user_tiebreakers, ( tie , indexTie ) => {
      //      if(  temporalEntries.entry.user_tiebreakers.length !== 0 ){
      //        temporalEntries.entry.user_tiebreakers.push( tie );
      //      }
      //      else { return }
      //    })
      //  }

      // console.log(temporalEntries)

      // Revisar con Diego
      if (temporalEntries.entry.picks_props) {
        temporalEntries.entry.pick_count = temporalEntries.entry.picks.length;
      }

      if (temporalEntries.entry.picks_props) {
        temporalEntries.entry.pick_props_count = temporalEntries.entry.picks_props.length;
      }

      this.setState({ allEntries: temporalEntries });
    };

    this.updateAfterRegister = (data) => {
      console.log('selectedEntry:', this.state.selectedEntry);

      // despues de registrarme desde el lobby principal de quiniela
      if (!this.state.selectedEntry) {
        this.setState(
          {
            withRegister: true,
            selectedEntry: data.entryId,
            selectedEntryData: data.allEntries[0],
            cambiarEntradas: true,
            allEntries: { entry: data.allEntries[0], other_entries: [] },
          },
          () => {
            this.setState({ cambiarEntradas: false});
            history.push({
              pathname: `/quiniela/${this.state.poolInfo.pool.slug}/${this.state.poolInfo.pool.id}/${this.state.selectedEntry}`,
            });
          }
        );
      }
    };

    /**********************
     * Socket callbacks
     **********************/
    this.fixtureUpdateCallback = (fixtureUpdate) => {
      if (!this.state.poolInfo) {
        return;
      }
      if (this.state.poolInfo.pool.status !== 'live') {
        return;
      }
      if (!this.state.standings) {
        return;
      }
      // let newPositions = this.state.standings.items;
      // let tempStandings = this.state.standings;
      let fixturesToLoad = [];
      let tempFixtures = this.state.fixtures;

      // console.log('%s %c','background: #2CB7DE; font-size:20px;','-------------> Socket Data For fixxutre update');
      // console.log( callbackFixt.results );

      fixtureUpdate.live_fixtures.forEach((liveFixture) => {
        let fixtureLiveIndex = _.findIndex(
          tempFixtures,
          (fixture) => fixture.id === liveFixture.id
        );

        if (fixtureLiveIndex <= -1) {
          return;
        }
        let _fixture = tempFixtures[fixtureLiveIndex];

        _fixture.score_away = liveFixture.score_away;
        _fixture.score_home = liveFixture.score_home;
        _fixture.winning_pick = liveFixture.winning_pick;
        _fixture.winning_pick_line = liveFixture.winning_pick_line;
        if (_fixture.sort_status !== 0) {
          _fixture.time_left = 0;
          _fixture.sort_status = 0;
          _fixture.enabled = 0;
          fixturesToLoad.push(_fixture.id);
          console.log('This fixture is Live', _fixture.id);
        }

        let liveResult = fixtureUpdate.results[liveFixture.feed_id];

        if (liveResult) {
          if (!_fixture.hasOwnProperty('stats')) {
            _fixture['stats'] = {};
          }
          _fixture['stats'] = liveResult;

          _fixture['stats']['quarter'] = liveResult.hasOwnProperty('quarter')
            ? liveResult.quarter
            : null;
          _fixture['stats']['timer'] = liveResult.timer;
          _fixture['stats']['score_home_first_half'] = liveResult.score_home_first_half;
          _fixture['stats']['score_away_first_half'] = liveResult.score_away_first_half;
          _fixture['stats']['score_home_second_half'] = liveResult.score_home_second_half;
          _fixture['stats']['score_away_second_half'] = liveResult.score_away_second_half;
          _fixture['events'] = {};
          _fixture['events'] = liveResult.events;
        }

        tempFixtures[fixtureLiveIndex] = _fixture;
      });

      this.setState({
        fixtures: tempFixtures,
        liveFixturesData: Date.now(),
      });
    };

    this.propUpdateCallback = (propUpdate) => {
      // console.log( 'Socket Data For Prop update');
      if (!this.state.poolInfo || this.state.poolInfo.pool.status !== 'live') {
        return;
      }
      if (this.state.standings === undefined) {
        return;
      }
      // let newPositions = this.state.standings.items;
      // let tempStandings = this.state.standings;
      let tempProps = this.state.fixtureProps;
      let propsToLoad = [];
      let updateData = false;
      // console.log( 'librtempProps', tempProps );
      if (!tempProps) {
        return;
      }

      tempProps.forEach((fixtureProp, idx) => {
        propUpdate.forEach((propUpdated) => {
          if (fixtureProp.id !== propUpdated.id) {
            return;
          }
          updateData = true;
          let tempFixtureProp = fixtureProp;
          tempFixtureProp.result = propUpdated.result;
          tempFixtureProp.winning_pick = propUpdated.winning_pick;
          tempFixtureProp.winning_prop = propUpdated.winning_prop;
          if (fixtureProp.sort_status !== 0) {
            tempFixtureProp.fixture.sort_status = 0;
            tempFixtureProp.fixture.time_left = 0;
            tempFixtureProp.time_left = 0;
            tempFixtureProp.sort_status = 0;
            tempFixtureProp.enabled = 0;
            propsToLoad.push(fixtureProp.id);
          }
          tempProps[idx] = tempFixtureProp;
        });
      });
      /**
       * Setting new Standigs
       * After updated Props
       * **/
      if (updateData) {
        this.setState({
          fixtureProps: tempProps,
          liveFixturesData: Date.now(),
        });
      }
    };

    this.fixtureCloseCallback = (fixtureID) => {
      // let tempStandings = this.state.standings;
      if (this.state.standings === undefined) {
        return;
      }
      //let newPositions = this.state.standings.items;
      //let tempStandings = this.state.standings;
      let tempFixtures = this.state.fixtures;
      let tempProps = this.state.fixtureProps;
      let fixtureIdx = 0;
      let tempItems = this.state.standings.items;

      if (!tempFixtures) {
        return;
      }

      let fixture = tempFixtures.find((_fixture, fixtIdx) => {
        fixtureIdx = fixtIdx;
        return _fixture.id === fixtureID;
      });
      // console.log(fixture);
      if (!fixture) {
        return;
      }

      fixture.finished = true;
      fixture.sort_status = 2;
      tempFixtures[fixtureIdx] = fixture;

      tempItems.forEach((_standing, standingIdx) => {
        if (!_standing.picks) {
          return;
        }

        let pickIndex = _.findIndex(_standing.picks, (_pick) => _pick.fixture_id === fixture.id);

        if (!pickIndex) {
          return;
        }

        let winningPick =
          this.state.poolInfo.pool.point_format === 'line'
            ? fixture.winning_pick_line
            : fixture.winning_pick;
        if (_standing.picks[pickIndex] && _standing.picks[pickIndex].pick === winningPick) {
          _standing.points += 1;
          _standing.original_points += 1;
        }
        tempItems[standingIdx] = _standing;
      });

      if (fixture.props) {
        if (!tempProps) {
          return;
        }

        tempProps.forEach((_prop, propIdx) => {
          if (_prop.fixture_id !== fixture.id) {
            return;
          }

          _prop.fixture.finished = true;
          _prop.fixture.sort_status = 2;
          tempProps[propIdx] = _prop;

          tempItems.forEach((_standing, standingIdx) => {
            if (!_standing.picks_props) {
              return;
            }

            let pickPropIdx = _.findIndex(
              _standing.picks_props,
              (_pickProp) => _pickProp.prop_id === _prop.id
            );
            if (pickPropIdx < 0) {
              return;
            }
            if (
              _standing.picks_props[pickPropIdx] &&
              _standing.picks_props[pickPropIdx] === _prop.winning_pick
            ) {
              _standing.points += 1;
              _standing.original_points += 1;
            }
            tempItems[standingIdx] = _standing;
          });
        });
      }
      this.setState({
        fixtures: tempFixtures,
        fixtureProps: tempProps,
        liveFixturesData: Date.now(),
        updatedStandings: true,
      });
    };

    this.closedPoolCallback = (closedPool) => {
      if (!this.state.poolInfo || !this.state.standings) {
        return;
      }

      //let newPositions = this.state.standings.items;
      let tempPoolInfo = this.state.poolInfo;
      let tempStandings = this.state.standings;
      //let tempPoolInfoPool = this.state.poolInfo.pool;
      let nextStandings = closedPool.standings;
      let prevStandings = this.state.standings.items;
      let currentEntriesData = this.state.allEntries;

      if (!closedPool && closedPool.pool) {
        return;
      }

      /**
       * Find if the available fixtureProps
       * exist in the updateProps
       * and update de winning pick
       */
      if (closedPool.pool !== tempPoolInfo.pool.id) {
        return;
      }

      tempPoolInfo.pool['closed'] = true;
      tempPoolInfo.pool['status'] = 'closed';

      prevStandings.forEach((prevStanding, prevStandingIdx) => {
        nextStandings.forEach((nextStanding, nextStandingIdx) => {
          if (nextStanding.entry_id !== prevStanding.entry_id) {
            return;
          }

          prevStanding['total_possibly_correct'] = nextStanding.points;
          prevStanding['points'] = nextStanding.points;
          prevStanding['rank'] = nextStanding.rank;
          prevStanding['original_points'] = nextStanding.points;
          prevStanding['user_prize']['base_amount'] = nextStanding.base_amount;
          prevStanding['user_prize']['amount'] = nextStanding.prize_amount;
          prevStanding['user_prize']['tax'] = nextStanding.prize_tax;
          prevStanding['prize_amount'] = nextStanding.prize_amount;
          prevStandings[prevStandingIdx] = prevStanding;
        });
      });

      prevStandings.sort(firstBy('rank').thenBy('entry_id'));
      console.log('Closed Pool', prevStandings[0].rank, prevStandings[0].entry_id);

      tempStandings['items'] = prevStandings;

      /**
       * Update the Entry Data with the final standings, to show updated data in the Usertopba
       **/
      if (currentEntriesData && currentEntriesData.entry) {
        currentEntriesData.entry = utils.updateEntry(currentEntriesData.entry, tempStandings.items);
      }

      this.setState({
        allEntries: currentEntriesData,
        poolInfo: tempPoolInfo,
        standings: tempStandings,
        liveFixturesData: Date.now(),
        updatedStandings: true,
      });
    };

    this.standingsUpdateCallback = (standingsUpdate) => {
      // If haven't standings or pool information
      if (!this.state.poolInfo || !this.state.standings) {
        return;
      }

      // Only update this pool
      if (standingsUpdate.poolId !== this.state.poolInfo.pool.id) {
        return;
      }

      let allStandingsUpdated = standingsUpdate.standings;

      let temporalPool = this.state.poolInfo;

      temporalPool.ties_distribution = standingsUpdate.ties_distribution;

      // update ties distribution
      this.setState({ poolInfo: temporalPool });

      let currentEntriesData = this.state.allEntries;
      //Asignar Premios
      let tempStandings = this.state.standings;
      // console.log('stateStandings: \n', tempStandings.items[0])
      //Update positions based in Ties distribution updated
      let newUpdatedPositions = this.updateStandingsAganar(
        standingsUpdate.standings,
        this.state.poolInfo.pool.prize_currency
      );
      // Mapear Standings
      let direction = 1;
      if (this.queryParams.order !== 'ASC') {
        direction = -1;
      }

      if (!this.state.poolInfo.closed && this.queryParams.sort !== 'nick') {
        // console.log('live and !nick filters ');
        standingsUpdate.standings.sort(
          firstBy('total_possibly_correct', direction).thenBy('entry_id')
        );
        if (this.queryParams.nick === null || this.queryParams.nick === '') {
          const howMany = 100 * this.queryParams.page;
          tempStandings.items = standingsUpdate.standings.slice(0, howMany);
        }
      }

      // newUpdatedPositions = utils.updateEntry(
      //   newUpdatedPositions,
      //   tempStandings,
      //   this.updateStandingsFixturePoints,
      //   this.updateStandingsPositions,
      //   this.updateStandingsAganar
      // );

      tempStandings['items'] = newUpdatedPositions;
      // console.log('Direction', direction);
      if (
        this.queryParams.sort !== 'nick' &&
        this.queryParams.nick !== null &&
        this.queryParams.nick !== ''
      ) {
        console.log('should sort nicks');
        tempStandings.items.sort(firstBy('total_possibly_correct', direction).thenBy('entry_id'));
      }

      if (currentEntriesData) {
        currentEntriesData.entry = utils.updateEntry(currentEntriesData.entry, allStandingsUpdated);
      }

      this.setState({
        selectedEntryData: currentEntriesData ? currentEntriesData.entry : null,
        allEntries: currentEntriesData,
        standings: tempStandings,
        liveFixturesData: Date.now(),
        updatedStandings: true,
      });
    };

    /**
     *This function close the First Register panel
     */
    this.closeFirstRegister = () => {
      console.log('closeFirst');
      this.setState({
        showPanelFirstRegister: false,
      });
    };

    /**
     * this function handles the redirection from whats app click
     */
    this.whatsAppInvitation = () => {
      console.log('WhatsApp invitation');
      branchUtils.redirectToWhatsApp(this.state.branchLink, 'open', this.state.poolInfo.pool.id);
    };

    /**
     * This function updates the branch Link after it is ready
     */
    this.updateBranchLink = (newLink) => {
      console.log('UpdateBranchLink', newLink);
      this.setState({
        branchLink: newLink,
      });
    };

    /**
     * this function Show the invite panel
     */
    this.showInviteFriendsPanel = () => {
      console.log('Show Invite Panel');
      this.setState({
        showInvitePanel: true,
      });
    };
    /**
     *This functon hide the Friends Panel
     */
    this.hideInviteFriendsPanel = () => {
      console.log('Hide Invite Panel');
      this.setState({ showInvitePanel: false, branchLink: null });
    };

    /**
     * This function update the standings after a scroll event
     * it receives the new standigs as an argument
     */
    this.updateScrollPostions = (newStandings) => {
      this.setState({ standings: newStandings });
    };

    /**
     * This function resets the searchLink property
     * and fetch the standings
     */
    this.resetNickValue = () => {
      this.setState(
        {
          searchNick: '',
          submittingSearch: true,
        },
        () => {
          this.queryParams.nick = '';
          this.queryParams.page = 1;
          API.fetchStandings(this.state.poolInfo.pool.id, this.queryParams).then((response) => {
            const responses = [response];

            try {
              utils.checkAxiosAllErrors(responses);
            } catch (e) {
              console.error(e);
              Sentry.captureException(e);
            }

            this.callbackSearchUser(response.data);
          });
        }
      );
    };

    /**
     * this function change the searchNick input value
     */
    this.changeSearchNick = (ev) => {
      // console.log('changeNick');
      this.setState({
        searchNick: ev.target.value,
      });
    };

    this.getBranchLink = () => {
      return branchUtils.createLink(
        utils.getUserInfo(),
        this.state.poolInfo.pool,
        'pool',
        this.state.poolInfo.pool.private
      );
    };

    this.closeEntryGift = (redirectToPool) => {
      this.setState(
        {
          showEntryGift: false,
          entryGiftPopUp: null,
        },
        () => {
          utils.removeEntryGift();
        }
      );
    };

    this.closePopUpDeposit = () => {
      this.setState(
        {
          showPopUpDeposit: false,
        },
        () => {
          utils.removePopUpDeposit();
        }
      );
    };

    /**
     * this function handles the callback of the Sort standings
     */
    this.callbackSort = (data) => {
      let newStandings = data;
      // this.setState({
      //   submittingSearch: true,
      // })
      console.log('prevStandings', newStandings);

      let newUpdatedPositions = newStandings.items;
      if (this.state.poolInfo.pool.status === 'live') {
        newUpdatedPositions = this.updateStandingsAganar(
          newStandings.items,
          this.state.poolInfo.pool.prize_currency
        );
      }
      // console.log('returned standings ', newUpdatedPositions);

      // newStandings.items = newUpdatedPositions;

      // console.log('newstandings', newStandings);

      this.setState(
        {
          emptyList: data.items.length === 0 ? true : false,
          standings: data,
          submittingSearch: false,
          liveFixtureData: Date.now(),
          sortChanged: true,
        },
        () => {
          this.setState({
            sortChanged: false,
          });
        }
      );
    };

    /**
     * This function handles the callback of a user search
     */
    this.callbackSearchUser = (data) => {
      console.log('CallbackSearchUser', data);
      let newStandings = data;
      if (this.state.poolInfo.pool.status === 'live' && this.queryParams.order !== 'nick') {
        console.log('HERE');
        data.items = this.updateStandingsAganar(
          newStandings.items,
          this.state.poolInfo.pool.prize_currency
        );
      }

      this.setState(
        {
          emptyList: data.items.length === 0 ? true : false,
          standings: data,
          submittingSearch: false,
          liveFixtureData: Date.now(),
          sortChanged: true,
        },
        () => {
          this.setState({
            sortChanged: false,
          });
        }
      );
    };

    /**
     * This function fetch the standings with the modified query params
     * it hast o rewrite the standings data, and store the standigs default
     */
    this.fetchSearchableStandings = (ev, obj) => {
      if (!this.state.poolInfo.pool === undefined) {
        return;
      }
      this.setState({ submittingSearch: true });

      this.queryParams.nick = this.state.searchNick;
      this.queryParams.page = 1;
      API.fetchStandings(this.state.poolInfo.pool.id, this.queryParams)
        .then((response) => {
          const responses = [response];

          try {
            utils.checkAxiosAllErrors(responses);
          } catch (e) {
            console.error(e);
            Sentry.captureException(e);
          }
          // console.log(response);
          this.callbackSearchUser(response.data);
        })
        .catch((error) => {
          console.error(error);
          Sentry.captureException(error);
          this.resetNickValue();
          // this.nickReset();
        });
    };

    this.fetchSearchableStandings = _.debounce(this.fetchSearchableStandings, 500);

    this.deleteNewUserStatus = () => {
      this.setState({ popUpNewUsers: null, popUpNewUsersStatus: false });
    };

    this.hideInviteFriends = () => {
      console.log('Close');
      localStorage.removeItem('createdPrivate');
      this.setState({ createdPrivate: false });
    };

    this.showSendMail = () => {
      this.setState((prevState) => {
        return {
          invitarAmigosMail: !prevState.invitarAmigosMail,
        };
      });
    };
  }

  changeStatusPool(poolId, fixtureID, sortStatus) {
    let tempAvailablePools = this.state.poolInfo;
    // console.log('changeStatus Pool', tempAvailablePools);
    // console.log('changeStatus Pool', poolId, fixtureID);
    tempAvailablePools.pool.status = 'live';
    tempAvailablePools.pool.upcoming = false;
    tempAvailablePools.pool.time_left = 0;

    let tempFixtures = this.state.fixtures;

    if (fixtureID) {
      tempFixtures.forEach((f) => {
        if (f.id === fixtureID) {
          f.enabled = false;
          f.time_left = 0;
          f.sort_status = sortStatus;
        }
      });
    } else {
      tempFixtures[0].enabled = false;
      tempFixtures[0].time_left = 0;
      tempFixtures[0].sort_status = sortStatus;
    }
    // tempFixtures[0].sort_status = 0;
    let tempFixturesProps = [];
    this.state.fixtureProps.forEach((fixtProps) => {
      if (fixtProps.fixture_id === fixtureID) {
        fixtProps.enabled = false;
        fixtProps.fixture.enabled = false;
        fixtProps.fixture.time_left = 0;
        fixtProps.sort_status = sortStatus;
        // fixtProps.sort_status = 0;
        tempFixturesProps.push(fixtProps);
      } else {
        tempFixturesProps.push(fixtProps);
      }
    });
    this.setState(
      {
        changePoolStatus: true,
        updatedPools: true,
        poolInfo: tempAvailablePools,
        fixtures: tempFixtures,
        liveFixturesData: Date.now(),
      },
      () => {
        this.setState({
          changePoolStatus: false,
          liveFixturesData: Date.now(),
        });
      }
    );
  }

  /**
   * This function reset the updateStandings state to false
   */
  handleUpdateReset() {
    // console.log('reset Update');
    this.setState({
      updatedStandings: false,
    });
  }

  /**
   * This function handles the Sort order in the TablasPostions
   * @param type
   */
  sortPositionList(type) {
    console.log('%c Sort PositionList', 'color:rgb(254, 86, 150); font-size:1.2em;', type);
    this.setState({
      submittingSearch: true,
    });
    // console.log(type);
    //let tempPositions = this.state.standings;
    let tempActiveSort = this.state.activeSort;
    //let tempItems = this.state.standings.items;
    let orderStatus;
    switch (type) {
      case 'posiciones':
        orderStatus = this.state.activeSort.posiciones ? -1 : 1;
        this.queryParams.sort = 'rank';
        // console.log(type, orderStatus);
        tempActiveSort.posiciones = !tempActiveSort.posiciones;
        if (tempActiveSort.posiciones) {
          tempActiveSort.jugadores = false;
          tempActiveSort.aciertos = false;
          tempActiveSort.premios = false;
        }
        break;
      case 'jugadores':
        orderStatus = this.state.activeSort.jugadores ? -1 : 1;
        this.queryParams.sort = 'nick';
        // console.log(type, orderStatus);
        tempActiveSort.jugadores = !tempActiveSort.jugadores;
        if (tempActiveSort.jugadores) {
          tempActiveSort.posiciones = false;
          tempActiveSort.aciertos = false;
          tempActiveSort.premios = false;
        }
        break;
      case 'aciertos':
        orderStatus = this.state.activeSort.aciertos ? -1 : 1;
        this.queryParams.sort = 'points';
        if (this.state.poolInfo.pool.status === 'closed') {
          // console.log(type, orderStatus);
          tempActiveSort.aciertos = !tempActiveSort.aciertos;
          if (tempActiveSort.aciertos) {
            tempActiveSort.jugadores = false;
            tempActiveSort.posiciones = false;
            tempActiveSort.premios = false;
          }
        } else {
          // console.log(type, orderStatus);
          tempActiveSort.aciertos = !tempActiveSort.aciertos;
          if (tempActiveSort.aciertos) {
            tempActiveSort.jugadores = false;
            tempActiveSort.posiciones = false;
            tempActiveSort.premios = false;
          }
        }
        break;
      case 'premios':
        orderStatus = this.state.activeSort.premios ? -1 : 1;
        this.queryParams.sort = 'prize';

        tempActiveSort.premios = !tempActiveSort.premios;
        break;
      default:
        return;
    }

    // tempPositions['items'] = tempItems;
    this.setState(
      (prevState) => {
        console.log(tempActiveSort);
        // console.log(prevState.activeSort);
        return {
          activeSort: tempActiveSort,
          sortChanged: !prevState.sortChanged,
          liveFixturesData: Date.now(),
          actualPage: 1,
          // standings: tempPositions,
        };
      },
      () => {
        //let temporalSortStatus = this.state.activeSort;
        this.queryParams.order = orderStatus === -1 ? 'DESC' : 'ASC';
        this.queryParams.page = 1;
        console.log(this.queryParams);
        API.fetchStandings(this.state.poolInfo.pool.id, this.queryParams)
          .then((response) => {
            console.log('Response', response.data);
            this.callbackSort(response.data);
          })
          .catch((error) => {
            Sentry.captureException(error, {
              extra: 'Error in quiniela fetching standings',
            });
          });
        // this.queryParams.sort = [];
        console.log('After Sort', this.state.standings.items[0].entry_id, this.state.sortChanged);
      }
    );
  }

  /**
   * This hchanges the state of the Pool after
   * the Quiniela registration deadline is 0
   */
  updateClosedQuiniela() {
    console.log('Closed!!!');
    let tempPool = this.state.poolInfo.pool;
    let tempPoolInfo = this.state.poolInfo;
    // tempPool['closed'] = true;
    tempPool['upcoming'] = false;
    tempPool['status'] = 'live';
    tempPoolInfo['pool'] = tempPool;
    this.setState({
      poolInfo: tempPoolInfo,
    });
  }

  /**
   * This function updates the State
   * of the fixture thtat just started
   * @param fixtureID
   */
  updateStartedFixture(fixtureID) {
    console.log('updated started fixture', fixtureID, 'Started');
    let indexStartedFixture, indexStartedProps;
    let tempFixturesList = this.state.fixtures;
    let tempPropsList = null;

    if (this.state.poolInfo.pool.format === 'mixed') {
      tempPropsList = this.state.fixtureProps;
      let startedProps = tempPropsList.find((prop, i) => {
        if (prop.fixture_id === fixtureID) {
          indexStartedProps = i;
          return prop;
        }
      });
      if (!startedProps || !startedProps.enabled) {
        return;
      }
      console.log('Prop', startedProps);
      startedProps['enabled'] = false;
      startedProps.fixture.enabled = false;
      tempPropsList[indexStartedProps] = startedProps;
      this.setState({
        fixtureProps: tempPropsList,
        liveFixtureData: Date.now(),
      });
    }

    // console.log(this.state.fixtures);
    let startedFixt = tempFixturesList.find((fixt, i) => {
      if (fixt.id === fixtureID) {
        indexStartedFixture = i;
        return fixt;
      }
    });

    if (!startedFixt || !startedFixt.enabled) {
      return;
    }
    console.log('Fixt', startedFixt);
    startedFixt['sort_status'] = 0;
    startedFixt['enabled'] = false;
    tempFixturesList[indexStartedFixture] = startedFixt;
    this.setState({
      fixtures: tempFixturesList,
      liveFixtureData: Date.now(),
    });
  }

  /**
   * This function handles the state
   * of the Fixture that has just finished
   * @param fixtureID
   */
  updateClosedFixture(fixtureID) {
    console.log('fixture', fixtureID, 'finished');
    let indexClosedFixture;
    let tempFixturesList = this.state.fixtures;
    let closedFixt = tempFixturesList.find((fixt, i) => {
      if (fixt.id === fixtureID) {
        indexClosedFixture = i;
        return closedFixt;
      }
    });
    if (closedFixt !== undefined) {
      closedFixt['sort_status'] = 2;
      closedFixt['closed'] = true;
      tempFixturesList[indexClosedFixture] = closedFixt;
      this.setState({
        fixtures: tempFixturesList,
      });
    }
  }

  reiniciarCambioDeEntrada() {
    this.setState({
      cambiarEntradas: null,
    });
  }

  /**
   * this function Change the Entry Id in a multy entry quiniela
   * @param value
   */
  changeSelectedEntry(value, type) {
    console.log('changeSelectedEntry', value, type);
    if (value === undefined) {
      return;
    }
    if (type !== 'silent') {
      this.setState({
        loadedPicks: false,
      });
    }
    this.setState({loadedPicks: false, mounted: false});

    API.getEntriesInfo(value)
    .then((response) => {
      let {entry} = response.data;
      this.setState({
        selectedEntry: value,
        selectedEntryData: entry,
        allEntries: response.data, // weird but ok...
        entries: response.data,
        loadedPicks: true,
        mounted: true,
        cambiarEntradas: true,
      }, () => {
        let currentEntriesData = this.state.allEntries;
        // console.log("CURRENTENTRIESUTILS:", this.state.standings);
        if (this.state.standings.items !== null) {
          currentEntriesData.entry = utils.updateEntry(currentEntriesData.entry, this.state.standings.items);
        }
        this.setState({cambiarEntradas: null});
      });

      return API.fetchSimilarEntries(value);
    })
    .then((response) => {
      let {similar_entries} = response.data;
      this.setState({
        similarPools: similar_entries,
      });
    })
    .catch((error) => {
      Sentry.captureException(error, {
        extra: 'Error fetching after change Selected Entry',
      });
    });

    // let similar_, entries_;
    // axios
    //   .all([API.getEntriesInfo(value), API.fetchSimilarEntries(value)])
    //   .then(
    //     axios.spread((entries, similar) => {
    //       entries_ = entries.data;
    //       similar_ = similar.data;
    //       let entryData;
    //       if (entries_.entry.id === value) {
    //         entryData = entries_.entry;
    //       } else {
    //         entryData = entries_.other_entries.find((entry) => entry.id === value);
    //       }
    //       let cambioE = true;
    //       let loadedP = true;
    //       if (type !== undefined) {
    //         cambioE = null;
    //         loadedP = false;
    //       }
    //       this.setState(
    //         {
    //           similarPools: similar_,
    //           selectedEntry: value,
    //           selectedEntryData: entryData,
    //           allEntries: entries_,
    //           entries: entries_,
    //           loadedPicks: true,
    //           mounted: true,
    //           // loadedPicks           :loadedP,
    //         },
    //         () => {
    //           this.setState({
    //             cambiarEntradas: cambioE,
    //           });

    //           // console.log(this.state.cambiarEntradas, this.state.loadedPicks);
    //           if (this.state.createdPrivate) {
    //             console.log('showInvite');
    //             // let link = this.getBranchLink().then((url) => {
    //             //   this.updateBranchLink(url, null, this.state.poolInfo.pool.id);
    //             // });
    //           }

    //           if (this.state.cambiarEntradas === null && this.state.loadedPicks === false) {
    //             //let entry = this.state.selectedEntry;
    //             console.log('changeThis');
    //             // let allInputs = $('label[data-entry] input');
    //             // let allLabels = $('label[data-entry]').removeClass('active');
    //             // let selected = $(`label[data-entry=${entry}] input`);
    //             // let selectedLabel = $(`label[data-entry=${entry}]`).addClass('active');
    //             history.push({
    //               pathname: `/quiniela/${this.state.poolInfo.pool.slug}/${this.state.poolInfo.pool.id}/${this.state.selectedEntry}`,
    //             });
    //           }
    //           let currentEntriesData = this.state.allEntries;

    //           console.log("CURRENTENTRIESUTILS:", this.state.standings);
    //           if (this.state.standings.items !== null) {
    //             currentEntriesData.entry = utils.updateEntry(currentEntriesData.entry, this.state.standings.items);
    //           }

    //           setTimeout(() => {
    //             this.reiniciarCambioDeEntrada();
    //           }, 2000);
    //         }
    //       );
    //     })
    //   )
    //   .catch((error) => {
    //     Sentry.captureException(error, {
    //       extra: 'Error fetching after change Selected Entry',
    //     });
    //   });
  }

  /**
   * This function Change the week in a full Quiniela
   * @param weekID
   */
  changeSelectedWeek(weekID) {
    if (this.state.poolInfo.pool.type === 'full') {
      this.setState({
        loadedPicks: false,
      });
      // console.log('%c Changing Week', 'background:#7BD900;font-size:20px  ;');

      API.fetchWeekFixtures(this.state.poolInfo.pool.id, weekID).then((response) => {
        // console.log(weekID, response.data.fixtures)
        this.setState({
          loadedPicks: true,
          poolWeekSelected: weekID,
          fixtures: response.data.fixtures,
          selectedWeekFixtures: response.data.fixtures.filter(
            (sel) => sel.week_id === this.state.poolWeekSelected
          ),
        });
      });
    }
  }

  /**
   * this function Shows the Cancel Panel
   * @param e
   */
  handleCancelClick(e) {
    console.log('%c HidePanel Cancel', 'font-size:20px');
    this.setState({
      showPanelCancel: false,
    });
  }

  /**
   * This Function shows the Panel to ReRegister in this quiniela
   */
  handleClickReRegister() {
    console.log('ReRegister');
    this.setState({
      showPanelReRegister: true,
    });
  }

  /**
   * This function handles the registerpanel for a first time registry
   */
  handleClickFirstRegister() {
    console.log('FirstRegister');
    this.setState({
      showPanelFirstRegister: true,
      updateCurrentEntry: true,
    });
  }

  /***
   * this function shows the Recomendation Panel
   */
  handleClickRecomendation() {
    console.log('Recomendation');
    setTimeout(() => {
      this.setState({
        showPanelReRegister: false,
        showPanelRecomendation: true,
      });
    }, 12000);
  }

  /**
   * This Function Redirects after ther user cancel an entry
   */
  redirectAfterCancel() {
    console.log('Redirecting after Cancel');
    this.updateUserInfo();
    this.setState({
      redirectToLobby: true,
    });
  }

  /**
   * This Function Redirects after ther user cancel the entry id of the current Entry
   */
  redirectAfterCancelCurrentEntry() {
    //const that = this;
    console.log('Redirecting after Cancel Current netry');
    this.setState(
      {
        redirectToSame: true,
      },
      () => {
        console.log('Redirect  aftercancel!');
      }
    );
  }

  /**
   * This Function refresh the data after ther user cancel an entry
   * it uses the Entry id and poolID to handle the fetch calls
   */
  refreshAfterCancel(newEntries, entryID, poolID, currentCancelation) {
    console.log('Refreshing after Cancel', this);
    let currentEntryID = Number(this.props.match.params.entryId);
    // console.log(currentEntryID , currentCancelation, newEntries);
    let isMycurrentEntryCancelled = currentCancelation.find((cancel) => {
      if (cancel.id === currentEntryID) {
        return true;
      }
    });

    let quinaID = poolID;
    // console.log('QuinaID',quinaID);
    let poolI_;
    let premios_;
    let user_;
    let fixtures_;
    let similar_;
    let entries;
    let standings_;
    let standGroups_;
    this.queryParams.page = 1;
    API.fetchUserData()
      .then((response) => {
        // console.log('Response USer Data Ypdated', response.data);
        setTimeout(() => {
          axios
          .all([
            API.fetchIndividualPool(quinaID),
            API.fetchPoolPrizes(quinaID),
            API.fetchUserData(),
            API.fetchFixtures(quinaID),
            API.fetchSimilarEntries(entryID),
            API.fetchStandings(quinaID, this.queryParams),
            API.fetchStandingsGroups(quinaID),
            API.fetchWeek(quinaID),
            API.getEntriesInfo(entryID),
          ])
          .then(
            axios.spread(
              (
                individual,
                prizes,
                usr,
                fixtures,
                similar,
                standings,
                standGroups,
                poolWeeks,
                allEntries
              ) => {
                const responses = [
                  individual,
                  prizes,
                  usr,
                  fixtures,
                  similar,
                  standings,
                  standGroups,
                  poolWeeks,
                  allEntries,
                ];
                try {
                  utils.checkAxiosAllErrors(responses);
                } catch (error) {
                  console.error(error);
                  Sentry.captureException(error);
                }
                // console.log('%c %s %o','background:#36A121; font-size:20px;','Refreshing AfterCancel ');
                // console.log(standings.data, );
                poolI_ = individual.data;
                premios_ = prizes.data;
                user_ = usr.data;
                fixtures_ = fixtures.data.fixtures;
                similar_ = similar.data;
                standings_ = standings.data;
                entries = allEntries.data;
                standGroups_ = standGroups.data;
                // fixtures_.sort(
                //   firstBy('sort_status', 1)
                //     .thenBy('deadline',-1)
                // );
                this.setState(
                  {
                    poolInfo: poolI_,
                    premios: premios_,
                    userData: user_,
                    fixtures: fixtures_,
                    similarPools: similar_,
                    standings: standings_,
                    allEntries: allEntries.data,
                    quinielaID: quinaID,
                    entries: allEntries.data,
                    standGroups: standGroups_,
                    poolWeeks: poolWeeks.data,
                    selectedEntry: allEntries.data.entry.id,
                    selectedEntryData: allEntries.data.entry,
                    showPanelReRegister: false,
                    showPanelFirstRegister: false,
                  },
                  () => {
                    console.log('CancelationCOmpleted');
                    if (
                      isMycurrentEntryCancelled !== undefined ||
                      isMycurrentEntryCancelled !== -1
                    ) {
                      setTimeout(() => {
                        this.changeSelectedEntry(this.state.selectedEntry);
                        history.push({
                          pathname: `/quiniela/${this.state.poolInfo.pool.slug}/${this.state.poolInfo.pool.id}/${this.state.selectedEntry}`,
                        });
                      }, 500);
                    }
                  }
                );
              }
            )
          )
          .catch((error) => {
            console.error(error);
            console.error('Issues initial fetch Calls');
            Sentry.captureException(error);
            this.setState({ redirectToLobby: true });
          });
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        console.error('Issues initial fetch Calls');
        Sentry.captureException(error);
        this.setState({ redirectToLobby: true });
      });
  }

  /**
   * REdirect to Lobby if there are not entries available
   */
  redirectToLobby() {
    console.log('REdirect To Lobby');
    this.updateUserInfo();
    this.setState({
      redirectToLobby: true,
    });
  }

  /***
   *This function Refresh after save Picks
   * it uses a given uerID to to replace the
   * data of the response to the state
   * @param entryId
   */
  refreshAfterGuardar(entryId, type) {
    // console.log("Refresh afterguardar",  type);
    // this.setState({
    //     logged:false,
    //     loaded:false,
    //     mounted:false,
    // })
    let quinaID = this.props.match.params.poolId;
    let poolI_;
    let premios_;
    let user_;
    let fixtures_;
    let similar_;
    let standings_;
    let my_pools_;
    let standGroups_;
    let poolWeeks_;
    let selectedEntryData;
    let allEntries_;
    this.queryParams.page = 1;
    if (type === 'save') {
      console.log('Just Save');
      axios
        .all([API.fetchSimilarEntries(entryId), API.getEntriesInfo(entryId)])
        .then(
          axios.spread((similar, allEntries) => {
            const responses = [similar, allEntries];

            try {
              utils.checkAxiosAllErrors(responses);
            } catch (e) {
              console.error(e);
              Sentry.captureException(e);
            }

            console.log(
              '%c %s %o',
              'background:#36A121; font-size:20px;',
              'Refreshing After Save '
            );
            // console.log(similar.data);
            similar_ = similar.data;
            allEntries_ = allEntries.data;
            if (allEntries_.entry.id === entryId) {
              selectedEntryData = allEntries_.entry;
            } else {
              selectedEntryData = allEntries_.other_entries.find((entry) => entry.id === entryId);
            }

            this.setState(
              (prevState) => {
                console.log('setting STate');
                return {
                  allEntries: allEntries.data,
                  entries: allEntries.data,
                  posiciones: standings_,
                  quinielaID: quinaID,
                  selectedEntry: entryId,
                  selectedEntryData: allEntries.data.entry,
                  showPanelFirstRegister: false,
                  showPanelRecomendation: false,
                  showPanelReRegister: false,
                  similarPools: similar_,
                };
              },
              () => {
                console.log('HERRRR');
                this.changeSelectedEntry(entryId, 'silent');
              }
            );
          })
        )
        .catch((error) => {
          Sentry.captureException(error, {
            extra: 'Error fetching Axios all  Bracket',
          });
        });
    } else {
      console.log('register__');
      setTimeout(() => {
        axios
        .all([
          API.fetchPoolPrizes(quinaID),
          API.fetchUserData(),
          API.fetchFixtures(quinaID),
          API.fetchSimilarEntries(entryId),
          API.fetchStandings(quinaID, this.queryParams),
          API.fetchStandingsGroups(quinaID),
          API.fetchWeek(quinaID),
          API.getEntriesInfo(entryId),
          API.fetchIndividualPool(quinaID),
        ])
        .then(
          axios.spread(
            (
              prizes,
              usr,
              fixtures,
              similar,
              standings,
              standGroups,
              poolWeeks,
              allEntries,
              individual,
            ) => {
              const responses = [
                prizes,
                usr,
                fixtures,
                similar,
                standings,
                standGroups,
                poolWeeks,
                allEntries,
                individual,
              ];

              try {
                utils.checkAxiosAllErrors(responses);
              } catch (e) {
                console.error(e);
                Sentry.captureException(e);
              }

              //Pools Privadas solo con Id desde pool/views
              console.log(
                '%c %s %o',
                'background:#36A121; font-size:20px;',
                'Refreshing AfterRE REgister ',
              );
              allEntries_ = allEntries.data;
              fixtures_ = fixtures.data.fixtures;
              poolI_ = individual.data;
              poolWeeks_ = poolWeeks.data;
              premios_ = prizes.data;
              similar_ = similar.data;
              standGroups_ = standGroups.data;
              standings_ = standings.data;
              user_ = usr.data;

              if (allEntries_.entry.id === entryId) {
                // console.log(allEntries_.entry.id, entryId)
                selectedEntryData = allEntries_.entry;
              } else {
                selectedEntryData = allEntries_.other_entries.find((entry) => entry.id === entryId);
              }
              // fixtures_.sort(
              //   firstBy('sort_status', 1)
              //     .thenBy('deadline',-1)
              // );
              let firstRegister = false;

              console.log("STANDING::", standings_);
              this.setState(
                (prevState) => {
                  if (prevState.allEntries === null) {
                    firstRegister = true;
                  }
                  return {
                    allEntries: allEntries.data,
                    entries: allEntries.data,
                    fixtures: fixtures_,
                    liveFixturesData: Date.now(),
                    loadedPicks: true,
                    my_pools: my_pools_,
                    poolInfo: poolI_,
                    poolWeeks: poolWeeks_,
                    posiciones: standings_,
                    premios: premios_,
                    quinielaID: quinaID,
                    selectedEntry: entryId,
                    selectedEntryData: allEntries.data.entry,
                    showPanelFirstRegister: false,
                    showPanelRecomendation: false,
                    showPanelReRegister: false,
                    similarPools: similar_,
                    standGroups: standGroups_,
                    standings: standings_,
                    userData: user_,
                    cambiarEntradas: true,
                  };
                },
                () => {
                  console.log('Refreshed After Guardar Complete', this.state);
                }
              );
              this.setState((prevState) => {
                let redirect = false;
                let withRegister = false;
                console.log('Quiniela REady', this.state, prevState);
                if (firstRegister) {
                  console.log('First REgister');
                  redirect = true;
                  withRegister = true;

                  this.changeSelectedEntry(this.state.selectedEntry);
                  history.push({
                    pathname: `/quiniela/${this.state.poolInfo.pool.slug}/${this.state.poolInfo.pool.id}/${this.state.selectedEntry}`,
                  });
                  // window.location.assign(this.state.selectedEntry);
                  return {
                    redirectLobbyWithEntry: redirect,
                    withRegister: withRegister,
                    logged: true,
                    loaded: true,
                    cambiarEntradas: false,
                    // loadedPicks: true,
                  };
                } else {
                  // this.changeSelectedEntry(this.state.selectedEntry, 'silent');
                  history.push({
                    pathname: `/quiniela/${this.state.poolInfo.pool.slug}/${this.state.poolInfo.pool.id}/${this.state.selectedEntry}`,
                  });
                  return {
                    logged: true,
                    loaded: true,
                    // loadedPicks: true,
                  };
                }
              });
            }
          )
        )
        .catch((error) => {
          Sentry.captureException(error, {
            extra: 'Error After register axios all',
          });
        });
      },1000);
    }
  }

  /**
   * This function MAkes a refresh
   * after the user insert a recommendations
   */
  refreshAfterRecomendation() {
    console.log('%c %s', 'font-size:20px; color:#009BF5;', 'Redireccting to Mis Quinielas');
    this.setState(
      {
        redirectMisQuinielas: true,
      },
      () => {
        utils.setUserInfo();
      }
    );
  }

  /**
   * This function Reset the positions in live quinielas
   * @param newPos
   */
  resetPosiciones(newPos) {
    console.log('Reseting Posiciones');
    this.setState({
      standings: newPos,
    });
  }

  /**
   * This function update the entries
   * And updates all the info in the quiniela Lobby
   */
  updateChildComponents() {
    console.log('Update Childs');
    API.getEntriesInfo(this.state.poolInfo.pool.id).then((response) => {
      this.setState({
        entries: response.data,
      });
    });
  }

  /**
   * This Function hide the cancel Panel
   */
  updatePanelCancelState() {
    // console.log("%c Show Panel Cancel", "font-size:20px");
    this.setState({
      showPanelCancel: true,
    });
  }

  /**
   * This function hides the recomendation Panel
   */
  updatePanelRecomendation() {
    // console.log('closeit');
    this.setState({
      showPanelRecomendation: false,
    });
  }

  /**
   * This function Hides the registerPanel for the first time register
   */
  updatePanelFirstRegister() {
    console.log('%c Close REgister Panel', 'font-size:20px');
    this.setState({
      showPanelFirstRegister: false,
    });
  }

  /**
   * This function Hides the reRegister panel
   */
  updatePanelReRegister() {
    console.log('%c Close REgister Panel', 'font-size:20px');
    this.setState({
      showPanelReRegister: false,
    });
  }

  /**
   * This position is triggered when the user completes an action
   * that involves the manipulation of the balance of his pickcoins or RealMoney
   */
  updateUserInfo() {
    console.log('Update User Info');
    API.fetchUserData()
      .then((response) => {
        utils.setUserInfo(response.data);
        this.setState({ userData: response.data });
      })
      .catch((error) => {
        Sentry.captureException(error, {
          extra: 'Error fetching user data',
        });
      });
  }

  /**
   * This function Updates the fixtures in a full quiniela
   * @param fixtures
   */
  updateFixtures(fixtures) {
    // console.log('%c UpdateFixtures', 'background:#5C63FF;font-size:24px;')
    this.setState({
      selectedWeekFixtures: fixtures,
    });
  }

  /**
   * this function change the state of the Pool
   * when it changed from upcoming to Live
   */
  quinielaLive() {
    // console.log('YA esta Live');
    if (this.state.poolInfo.pool.deadline < 0 && this.state.poolInfo.pool.status === 'upcoming') {
      let tempPool = this.state.poolInfo.pool;
      let tempPoolInfo = this.state.poolInfo;
      tempPool['status'] = 'live';
      tempPool['upcoming'] = false;
      tempPoolInfo['pool'] = tempPool;
      this.setState({
        poolInfo: tempPoolInfo,
      });
    }
  }

  updatePoolInfo() {
    API.fetchIndividualPool(this.state.quinielaID).then((response) => {
      this.setState({}, () => {
        this.setState({poolInfo: response.data})
      });
    });
  }

  /** **********************
   * Lifecycle Methods
   * **********************/

  componentDidMount() {
    console.log('cargar quiniela');

    gaUtils.handleDocumentAttributes(this.props.match);

    // if(utils.isNewUserForProduct()){
    //   utils.deletePopUpNewUser()
    // }

    clipboardCode = new Clipboard('.copiar-btn');
    clipboardCode.on('success', (e) => {
      const wich = e.trigger.attributes['data-clipboard-target'].value;
      console.log('wich', wich);
      $(wich).addClass('copied');
      e.clearSelection();
    });


    // Timestamp to reload data if user has active tab
    this.focusTimestamp = Date.now()

    this.focus = () => {
      const sec = (Date.now() - this.focusTimestamp) / 1000
      const secondsToRefresh = 30

      if (sec >= secondsToRefresh) {
        this.refreshData()
        this.focusTimestamp = Date.now()
      }
    }

    window.addEventListener('focus', this.focus)

    this.resetRefreshTick = () => {
      clearTimeout(this.refreshTick)

      this.refreshTick = setTimeout(() => {
        this.refreshData()
      }, TIME_CHECK_AFTER_WS_CHANGE)
    }

    let quinaID = this.props.match.params.poolId;
    let entryID = this.props.match.params.entryId;

    const entryGiftPopUp = utils.getEntryGift();
    let showEntryGift = false;

    if (
      entryGiftPopUp &&
      entryGiftPopUp.hasOwnProperty('pool_id') &&
      entryGiftPopUp.pool_id === quinaID
    ) {
      showEntryGift = true;
    }

    const popUpDeposit = utils.getPopUpDeposit();
    let showPopUpDeposit = false

    if (
      popUpDeposit &&
      popUpDeposit.hasOwnProperty('pool_id') &&
      popUpDeposit.pool_id === quinaID
    ) {
      showPopUpDeposit = true;
    }

    let poolI_;
    let premios_;
    let user_;
    let fixtures_;
    let parley_;
    let parleyDeadlines_;
    let firstFixture_;
    let similar_;
    let standings_;
    let standGroups_;
    let poolWeeks_;
    let currentWeek_;
    let allEntries_;

    const createdPrivate = JSON.parse(localStorage.getItem('createdPrivate'));
    const showInvitePanelMundial = utils.getShowInvitePanel();
    if (showInvitePanelMundial === 1) {
      utils.clearShowInvitePanel();
    }

    let popUpNewUserStatus = utils.getPopUpNewUsers();
    if (popUpNewUserStatus !== null) {
      // console.log('New User !')
      this.setState({
        popUpNewUsers: popUpNewUserStatus,
        popUpNewUsersStatus: true,
      });
    }

    API.fetchUserData()
      .then((response) => {
        // console.log('Response', response.data);
        if (response.data.user) {
          this.setState(() => {
            utils.setUserInfo(response.data);
            return {
              userData: response.data,
            };
          });
          // console.log('Logged', quinaID, this);
          /**
           * Get Entries if the query EntryID isnt null
           */
          if (quinaID && entryID) {
            console.log('------------- Entry and Quina ------------------', this.props);
            axios
              .all([
                API.fetchIndividualPool(quinaID),
                API.fetchPoolPrizes(quinaID),
                API.fetchUserData(),
                API.fetchFixtures(quinaID),
                API.fetchSimilarEntries(entryID),
                API.fetchStandings(quinaID, this.queryParams),
                API.fetchStandingsGroups(quinaID),
                API.fetchWeek(quinaID),
                API.getEntriesInfo(entryID),
                API.fetchTickets(quinaID, 'pool'),
              ])
              .then(
                axios.spread(
                  (
                    individual,
                    prizes,
                    usr,
                    fixtures,
                    similar,
                    standings,
                    standGroups,
                    poolWeeks,
                    entries_,
                    tickets_
                  ) => {
                    const responses = [
                      individual,
                      prizes,
                      usr,
                      fixtures,
                      similar,
                      standings,
                      standGroups,
                      poolWeeks,
                      tickets_,
                    ];
                    try {
                      utils.checkAxiosAllErrors(responses);
                    } catch (error) {
                      console.error(error);
                      Sentry.captureException(error);
                    }

                    poolI_ = individual.data;
                    premios_ = prizes.data;
                    user_ = usr.data;
                    fixtures_ = fixtures.data.fixtures;
                    parley_ = fixtures.data.parlay_blocks;
                    parleyDeadlines_ = fixtures.data.parlay_deadlines;
                    firstFixture_ = fixtures.data.first;
                    similar_ = similar.data;
                    standings_ = standings.data;
                    standGroups_ = standGroups.data;
                    poolWeeks_ = poolWeeks.data;
                    currentWeek_ = poolWeeks.data.current;
                    allEntries_ = entries_.data;
                    tickets_ = tickets_.data.tickets;

                    // console.log('EL DATO DE LA QUINIELA', poolI_);
                    /**
                     * API Socket Calls
                     */
                    API.subscribeSocketLiveFixtures(
                      (callbackFixt) => {
                        this.resetRefreshTick()
                        this.fixtureUpdateCallback(callbackFixt);
                      },
                      (callbackPropUp) => {
                        this.resetRefreshTick()
                        this.propUpdateCallback(callbackPropUp);
                      },
                      (fixtureID) => {
                        this.resetRefreshTick()
                        this.fixtureCloseCallback(fixtureID);
                      },
                      (closedPool) => {
                        this.resetRefreshTick()
                        this.closedPoolCallback(closedPool);
                      },
                      (standingsUpdate) => {
                        this.resetRefreshTick()
                        this.standingsUpdateCallback(standingsUpdate);
                      },
                      (standingsWeeklyUpdated) => {
                        this.resetRefreshTick()
                        SubscriptionRxs.announceWeeklyStandingsUpdatedsUpdated(
                          standingsWeeklyUpdated
                        );
                      }
                    );
                    this.setState(
                      () => {
                        // if(!allEntries_.response.success){ console.log('Not Valid Entry')}
                        //   console.log('Entry REsponse', entries_)
                        let tempProps = [];
                        /**
                         * List of individual Props to handle in main state
                         */
                        fixtures_.forEach((fixture) => {
                          if (poolI_.pool.format === 'mixed' && fixture.props) {
                            fixture.props.map((prop) => {
                              let tempObj = prop;
                              tempObj['fixture'] = {
                                id: fixture.id,
                                sort_status: fixture.sort_status,
                                time_left: fixture.time_left,
                                suspended: fixture.suspended,
                                enabled: fixture.enabled,
                                deadline: fixture.deadline,
                                home_team: {
                                  id: fixture.home_team_id,
                                  abbreviation: fixture.home_team.abbreviation,
                                },
                                away_team: {
                                  id: fixture.away_team_id,
                                  abbreviation: fixture.away_team.abbreviation,
                                },
                              };
                              tempObj['enabled'] = fixture.enabled;
                              tempProps.push(tempObj);
                            });
                          }
                        });

                        if (poolI_.pool.status != 'closed' && this.queryParams.order !== 'nick') {
                          if (poolI_.parlay_flag === false) {
                            standings_?.items?.sort(
                              firstBy('possible_rank')
                              .thenBy('total_possibly_correct', -1)
                              .thenBy('entry_id')
                            );
                          } else {
                            standings_?.items?.sort(
                              firstBy('total_possibly_correct', -1)
                              .thenBy('parlay_elimination')
                              .thenBy('entry_id')
                            );
                          }
                        } else if (poolI_.pool.closed) {
                          if (poolI_.parlay_flag === false) {
                            standings_?.items?.sort(firstBy('rank', 1).thenBy('entry_id'));
                          }
                        }

                        let balanceEnough = utils.isBalanceEnough(
                          poolI_.pool.entry_currency,
                          poolI_.pool.entry_fee
                        );

                        return {
                          entries: allEntries_,
                          ticketsAvailable: tickets_,
                          allEntries: allEntries_,
                          createdPrivate: createdPrivate,
                          fixtures: fixtures_,
                          parley: parley_,
                          parleyDeadlines: parleyDeadlines_,
                          firstFixture: firstFixture_,
                          enoughBalance: !balanceEnough && !tickets_.length ? false : true,
                          poolInfo: poolI_,
                          fixtureProps: tempProps,
                          poolWeeks: poolWeeks_,
                          poolWeekSelected: currentWeek_.id,
                          premios: premios_,
                          loadedPicks: true,
                          quinielaID: quinaID,
                          selectedEntry: allEntries_.entry.id,
                          withRegister: true,
                          showEntryGift: showEntryGift,
                          entryGiftPopUp: entryGiftPopUp,
                          showPopUpDeposit: showPopUpDeposit,
                          similarPools: similar_,
                          standGroups: standGroups_,
                          selectedEntryData: allEntries_.entry,
                          standings: standings_,
                          defaultStandings: standings_,
                          userData: user_,
                        };
                      },
                      () => {
                        // console.log(poolI_)
                        if (poolI_.pool.status === 'upcoming' && showInvitePanelMundial) {
                          branchUtils
                            .createLink(utils.getUserInfo(), poolI_.pool, 'pool')
                            .then((url) => {
                              console.log('branch response', url);
                              this.setState({
                                showInvitePanel: true,
                                branchLink: url,
                              });
                            });
                        }

                        if (poolI_.pool.status === 'live') {
                          // console.log('UpdateStandings in live');
                          let temporalStandings = this.state.standings;
                          let updatedPositions = this.updateStandingsAganar(
                            this.state.standings.items,
                            this.state.poolInfo.pool.prize_currency
                          );
                          // console.log(updatedPositions)
                          let currentEntriesData = this.state.allEntries;
                          updatedPositions?.forEach((position) => {
                            if (position.entry_id === currentEntriesData.entry.id) {
                              currentEntriesData.entry.standing.points =
                                position.total_possibly_correct;
                              currentEntriesData.entry.standing.total_possibly_correct =
                                position.total_possibly_correct;
                              currentEntriesData.entry.standing.rank = position.rank;
                              currentEntriesData.entry.rank = position.rank;
                              if (currentEntriesData.entry.user_prize === null) {
                                currentEntriesData.entry.user_prize = {
                                  base_amount: position.user_prize.base_amount,
                                  description: position.user_prize.description,
                                };
                              }
                            }
                          });

                          if (currentEntriesData.entry) {
                            let newAmount = this.state.poolInfo.ties_distribution.find((tie) => {
                              if (tie.rank === currentEntriesData.entry.standing.rank) {
                                return tie;
                              }
                            });
                            // console.log(newAmount);
                            if (newAmount) {
                              // console.log(newAmount.prize_amount / newAmount.ties);
                              if (!currentEntriesData.entry.user_prize) {
                                currentEntriesData.entry.user_prize = {
                                  base_amount: newAmount.prize_amount,
                                  description: null,
                                };
                              }
                            }
                          }

                          temporalStandings['items'] = updatedPositions;
                          // console.log(updatedPositions.length);
                          this.setState({
                            selectedEntryData: currentEntriesData.entry,
                            allEntries: currentEntriesData,
                            standings: temporalStandings,
                          });
                        }
                      }
                    );
                    this.setState(
                      () => {
                        // console.log('Quiniela REady',this.state);
                        return {
                          logged: utils.isLogged(),
                          loaded: true,
                          loadedPicks: true,
                          createdPrivate: createdPrivate,
                        };
                      },
                      () => {
                        let fechaAhora = Date.now();
                        let fechaDeadline = this.state.poolInfo.pool.deadline;
                        let fechaLimite = moment(fechaDeadline).diff(fechaAhora);
                        history.push({
                          pathname: `/quiniela/${this.state.poolInfo.pool.slug}/${this.state.poolInfo.pool.id}/${this.state.selectedEntry}`,
                        });

                        if (this.state.createdPrivate) {
                          console.log('showInvite');
                          this.getBranchLink().then((url) => {
                            this.showInviteFriendsPanel();
                            this.updateBranchLink(url, null, this.state.poolInfo.pool.id);
                          });
                        }
                        if (fechaLimite < 0) {
                          this.quinielaLive();
                        }
                      }
                    );
                  }
                )
              )
              .catch((error) => {
                console.error(error);
                console.error('Issues initial fetch Calls EntryId and poolID');
                Sentry.captureException(error);
                this.setState({ redirectToQuinielaLobby: true });
              });
            //   .catch(error => {
            //     console.log(error);
            //     this.setState({redirectToMainLobby:true,});
            // });
          } else {
            /**
             * Fetch Data if the query EntryID is null
             */
            console.log('------Fetch data for Just QuinaID------');
            axios
              .all([
                API.fetchIndividualPool(quinaID),
                API.fetchPoolPrizes(quinaID),
                API.fetchUserData(),
                API.fetchFixtures(quinaID),
                API.fetchSimilarPools(quinaID),
                API.fetchStandings(quinaID, this.queryParams),
                API.fetchStandingsGroups(quinaID),
                API.fetchWeek(quinaID),
                API.fetchTickets(quinaID, 'pool'),
              ])
              .then(
                axios.spread(
                  (
                    individual,
                    prizes,
                    usr,
                    fixtures,
                    similar,
                    standings,
                    standGroups,
                    poolWeeks,
                    tickets_
                  ) => {
                    const responses = [
                      individual,
                      prizes,
                      usr,
                      fixtures,
                      similar,
                      standings,
                      standGroups,
                      poolWeeks,
                      tickets_,
                    ];
                    try {
                      utils.checkAxiosAllErrors(responses);
                    } catch (error) {
                      console.error(error);
                      Sentry.captureException(error);
                    }

                    poolI_ = individual.data;
                    premios_ = prizes.data;
                    user_ = usr.data;
                    fixtures_ = fixtures.data.fixtures;
                    parley_ = fixtures.data.parlay_blocks;
                    parleyDeadlines_ = fixtures.data.parlay_deadlines;
                    firstFixture_ = fixtures.data.first;
                    similar_ = similar.data;
                    standings_ = standings.data;
                    standGroups_ = standGroups.data;
                    poolWeeks_ = poolWeeks.data;
                    currentWeek_ = poolWeeks.data.current;
                    tickets_ = tickets_.data.tickets;
                    // console.log('tickets_', tickets_);

                    // console.log(responses);

                    /**
                     * API Socket Calls
                     */
                    API.subscribeSocketLiveFixtures(
                      (callbackFixt) => {
                        this.fixtureUpdateCallback(callbackFixt);
                      },
                      (callbackPropUp) => {
                        this.propUpdateCallback(callbackPropUp);
                      },
                      (fixtureID) => {
                        this.fixtureCloseCallback(fixtureID);
                      },
                      (closedPool) => {
                        this.closedPoolCallback(closedPool);
                      },
                      (standingsUpdate) => {
                        this.standingsUpdateCallback(standingsUpdate);
                      },
                      (standingsWeeklyUpdated) => {
                        SubscriptionRxs.announceWeeklyStandingsUpdatedsUpdated(
                          standingsWeeklyUpdated
                        );
                      }
                    );
                    this.setState(
                      () => {
                        let tempProps = [];
                        /**
                         * List of individual Props to handle in main state
                         */
                        fixtures_.forEach((fixture) => {
                          if (poolI_.pool.format === 'mixed' && fixture.props) {
                            fixture.props.map((prop) => {
                              let tempObj = prop;
                              tempObj['fixture'] = {
                                id: fixture.id,
                                sort_status: fixture.sort_status,
                                time_left: fixture.time_left,
                                enabled: fixture.enabled,
                                deadline: fixture.deadline,
                                suspended: fixture.suspended,
                                home_team: {
                                  id: fixture.home_team_id,
                                  abbreviation: fixture.home_team.abbreviation,
                                },
                                away_team: {
                                  id: fixture.away_team_id,
                                  abbreviation: fixture.away_team.abbreviation,
                                },
                              };
                              tempObj['enabled'] = fixture.enabled;
                              tempProps.push(tempObj);
                            });
                          }
                        });

                        if (poolI_.pool.status === 'live' && this.queryParams.order !== 'nick') {
                          if (poolI_.parlay_flag === false) {
                            console.log('First  sort', standings_.items.length);
                            standings_?.items?.sort(
                              firstBy('total_possibly_correct', -1).thenBy('entry_id')
                            );
                          } else {
                            standings_?.items?.sort(
                              firstBy('total_possibly_correct', -1)
                              .thenBy('parlay_elimination')
                              .thenBy('entry_id')
                            );
                          }
                        } else if (poolI_.pool.closed) {
                          if (poolI_.parlay_flag === false) {
                            console.log('default sort closed wothout entry  ');
                            if (standings_.items && standings_?.items?.sort !== null) {
                              standings_?.items?.sort(firstBy('rank', 1).thenBy('entry_id'));
                            }
                          }
                        }

                        let balanceEnough = utils.isBalanceEnough(
                          poolI_.pool.entry_currency,
                          poolI_.pool.entry_fee
                        );

                        return {
                          entries: [],
                          ticketsAvailable: tickets_,
                          fixtures: fixtures_,
                          parley: parley_,
                          parleyDeadlines: parleyDeadlines_,
                          firstFixture: firstFixture_,
                          createdPrivate: createdPrivate,
                          poolInfo: poolI_,
                          enoughBalance: !balanceEnough && !tickets_.length ? false : true,
                          fixtureProps: tempProps,
                          poolWeeks: poolWeeks_,
                          poolWeekSelected: currentWeek_.id,
                          premios: premios_,
                          loadedPicks: true,
                          quinielaID: quinaID,
                          selectedEntry: null,
                          selectedEntryData: null,
                          withRegister: false,
                          similarPools: similar_,
                          standGroups: standGroups_,
                          defaultStandings: standings_,
                          standings: standings_,
                          userData: user_,
                          showEntryGift: showEntryGift,
                          entryGiftPopUp: entryGiftPopUp,
                          showPopUpDeposit: showPopUpDeposit,
                        };
                      },
                      () => {
                        if (poolI_.pool.status === 'live') {
                          // UpdateStandings in live
                          let temporalStandings = this.state.standings;
                          let updatedPositions = this.updateStandingsAganar(
                            this.state.standings.items,
                            this.state.poolInfo.pool.prize_currency
                          );
                          // updatedPositions.length
                          temporalStandings['items'] = updatedPositions;
                          this.setState({
                            standings: temporalStandings,
                          });
                        }
                      }
                    );
                    this.setState(
                      () => {
                        // Quiniela Ready
                        return {
                          logged: utils.isLogged(),
                          loaded: true,
                          loadedPicks: true,
                          createdPrivate: createdPrivate,
                        };
                      },
                      () => {
                        if (this.state.createdPrivate) {
                          // ShowInvite
                          this.getBranchLink().then((url) => {
                            this.updateBranchLink(url, null, this.state.poolInfo.pool.id);
                          });
                        }
                        let fechaAhora = Date.now();
                        let fechaDeadline = this.state.poolInfo.pool.deadline;
                        let fechaLimite = moment(fechaDeadline).diff(fechaAhora);
                        // FechaLimite
                        if (fechaLimite < 0) {
                          this.quinielaLive();
                        }
                      }
                    );
                  }
                )
              )
              .catch((error) => {
                console.error(error);
                Sentry.captureMessage('Breaking Fetching Data');
                Sentry.captureException(error);
                this.setState({ redirectToLobby: true });
              });
          }
        }
      })
      .catch((error) => {
        console.error(error);
        Sentry.captureException(error);
        this.setState({ redirectToLobby: true });
      });
  }

  componentWillUnmount() {
    console.log('Unmounting quinielas');
    API.unSubscribeSocketLiveFixtures();

    clearTimeout(this.refreshTick)
  }

  refreshData = () => {
    const quinaID = this.props.match.params.poolId
    const entryID = this.props.match.params.entryId
  
    let poolI_
    let premios_
    let user_
    let fixtures_
    let parley_
    let parleyDeadlines_
    let firstFixture_
    let similar_
    let standings_
    let standGroups_
    let poolWeeks_
    let currentWeek_
    let allEntries_
  
    API.fetchUserData()
      .then((response) => {
        if (response.data.user) {
          this.setState(() => {
            utils.setUserInfo(response.data)
            return {
              userData: response.data
            }
          })

          /**
             * Get Entries if the query EntryID isnt null
             */
          if (quinaID && entryID) {  
            axios
              .all([
                API.fetchIndividualPool(quinaID),
                API.fetchPoolPrizes(quinaID),  
                API.fetchUserData(),
                // API.fetchFixtures(quinaID),
                API.fetchWeekFixtures(quinaID, this.state.poolWeekSelected),
                API.fetchSimilarEntries(entryID),
                API.fetchStandings(quinaID, this.queryParams),
                API.fetchStandingsGroups(quinaID),
                API.fetchWeek(quinaID),
                API.getEntriesInfo(entryID),
                API.fetchTickets(quinaID, 'pool')
              ])
              .then(
                axios.spread(
                  (
                    individual,
                    prizes,
                    usr,
                    fixtures,
                    similar,
                    standings,
                    standGroups,
                    poolWeeks,
                    entries_,
                    tickets_
                  ) => {
                    const responses = [
                      individual,
                      prizes,
                      usr,
                      fixtures,
                      similar,
                      standings,
                      standGroups,
                      poolWeeks,
                      tickets_
                    ]
                    try {
                      utils.checkAxiosAllErrors(responses)
                    } catch (error) {
                      console.error(error)
                      Sentry.captureException(error)
                    }
  
                    poolI_ = individual.data
                    premios_ = prizes.data
                    user_ = usr.data
                    fixtures_ = fixtures.data.fixtures
                    parley_ = fixtures.data.parlay_blocks
                    parleyDeadlines_ = fixtures.data.parlay_deadlines
                    
                    firstFixture_ = fixtures.data.first
                    similar_ = similar.data
                    standings_ = standings.data
                    standGroups_ = standGroups.data
                    poolWeeks_ = poolWeeks.data
                    currentWeek_ = poolWeeks.data.current
                    allEntries_ = entries_.data
                    tickets_ = tickets_.data.tickets
                    
                    this.setState(
                      () => {
                        const tempProps = []

                        /**
                           * List of individual Props to handle in main state
                           */
                        fixtures_.forEach((fixture) => {
                          if (poolI_.pool.format === 'mixed' && fixture.props) {
                            fixture.props.map((prop) => {
                              const tempObj = prop
                              tempObj.fixture = {
                                id: fixture.id,
                                sort_status: fixture.sort_status,
                                time_left: fixture.time_left,
                                suspended: fixture.suspended,
                                enabled: fixture.enabled,
                                deadline: fixture.deadline,
                                home_team: {
                                  id: fixture.home_team_id,
                                  abbreviation: fixture.home_team.abbreviation
                                },
                                away_team: {
                                  id: fixture.away_team_id,
                                  abbreviation: fixture.away_team.abbreviation
                                }
                              }
                              tempObj.enabled = fixture.enabled
                              tempProps.push(tempObj)
                            })
                          }
                        })

  
                        if (poolI_.pool.status != 'closed' && this.queryParams.order !== 'nick') {
                          if (poolI_.parlay_flag === false) {
                            standings_?.items?.sort(
                              firstBy('possible_rank')
                                .thenBy('total_possibly_correct', -1)
                                .thenBy('entry_id')
                            )
                          } else {
                            standings_?.items?.sort(
                              firstBy('total_possibly_correct', -1)
                                .thenBy('parlay_elimination')
                                .thenBy('entry_id')
                            )
                          }
                        } else if (poolI_.pool.closed) {
                          if (poolI_.parlay_flag === false) {
                            standings_?.items?.sort(firstBy('rank', 1).thenBy('entry_id'))
                          }
                        }
  
                        const balanceEnough = utils.isBalanceEnough(
                          poolI_.pool.entry_currency,
                          poolI_.pool.entry_fee
                        )

                        return {
                          entries: allEntries_,
                          ticketsAvailable: tickets_,
                          allEntries: allEntries_,
                          fixtures: fixtures_,
                          parley: parley_,
                          parleyDeadlines: parleyDeadlines_,
                          firstFixture: firstFixture_,
                          enoughBalance: !(!balanceEnough && !tickets_.length),
                          poolInfo: poolI_,
                          // fixtureProps: tempProps,
                          poolWeeks: poolWeeks_,
                          poolWeekSelected: this.state.poolWeekSelected,
                          premios: premios_,
                          loadedPicks: true,
                          quinielaID: quinaID,
                          selectedEntry: allEntries_.entry.id,
                          withRegister: true,
                          similarPools: similar_,
                          standGroups: standGroups_,
                          selectedEntryData: allEntries_.entry,
                          standings: standings_,
                          defaultStandings: standings_,
                          userData: user_,
                          selectedWeekFixtures: fixtures_.filter(
                            (sel) => sel.week_id === this.state.poolWeekSelected
                          ),
                        }
                      },
                      () => {
                        if (poolI_.pool.status === 'live') {
                          const temporalStandings = this.state.standings
                          const updatedPositions = this.updateStandingsAganar(
                            this.state.standings.items,
                            this.state.poolInfo.pool.prize_currency
                          )
                          const currentEntriesData = this.state.allEntries
                          updatedPositions?.forEach((position) => {
                            if (position.entry_id === currentEntriesData.entry.id) {
                              currentEntriesData.entry.standing.points =
                                  position.total_possibly_correct
                              currentEntriesData.entry.standing.total_possibly_correct =
                                  position.total_possibly_correct
                              currentEntriesData.entry.standing.rank = position.rank
                              currentEntriesData.entry.rank = position.rank
                              if (currentEntriesData.entry.user_prize === null) {
                                currentEntriesData.entry.user_prize = {
                                  base_amount: position.user_prize.base_amount,
                                  description: position.user_prize.description
                                }
                              }
                            }
                          })
  
                          if (currentEntriesData.entry) {
                            const newAmount = this.state.poolInfo.ties_distribution.find((tie) => {
                              if (tie.rank === currentEntriesData.entry.standing.rank) {
                                return tie
                              }
                            })
                            if (newAmount) {
                              if (!currentEntriesData.entry.user_prize) {
                                currentEntriesData.entry.user_prize = {
                                  base_amount: newAmount.prize_amount,
                                  description: null
                                }
                              }
                            }
                          }
  
                          temporalStandings.items = updatedPositions
                          this.setState({
                            selectedEntryData: currentEntriesData.entry,
                            allEntries: currentEntriesData,
                            standings: temporalStandings
                          })
                        }
                      }
                    )
                    this.setState(
                      () => {
                        return {
                          logged: utils.isLogged(),
                          loaded: true,
                          loadedPicks: true
                        }
                      },
                      () => {
                        const fechaAhora = Date.now()
                        const fechaDeadline = this.state.poolInfo.pool.deadline
                        const fechaLimite = moment(fechaDeadline).diff(fechaAhora)
                        history.push({
                          pathname: `/quiniela/${this.state.poolInfo.pool.slug}/${this.state.poolInfo.pool.id}/${this.state.selectedEntry}`
                        })
  
                        if (fechaLimite < 0) {
                          this.quinielaLive()
                        }
                      }
                    )
                  }
                )
              )
              .catch((error) => {
                console.error(error)
                console.error('Issues initial fetch Calls EntryId and poolID')
                Sentry.captureException(error)
                this.setState({ redirectToQuinielaLobby: true })
              })
          } else {
            /**
               * Fetch Data if the query EntryID is null
               */
            console.log('------Fetch data for Just QuinaID------')
            axios
              .all([
                API.fetchIndividualPool(quinaID),
                API.fetchPoolPrizes(quinaID),
                API.fetchUserData(),
                API.fetchFixtures(quinaID),
                API.fetchSimilarPools(quinaID),
                API.fetchStandings(quinaID, this.queryParams),
                API.fetchStandingsGroups(quinaID),
                API.fetchWeek(quinaID),
                API.fetchTickets(quinaID, 'pool')
              ])
              .then(
                axios.spread(
                  (
                    individual,
                    prizes,
                    usr,
                    fixtures,
                    similar,
                    standings,
                    standGroups,
                    poolWeeks,
                    tickets_
                  ) => {
                    const responses = [
                      individual,
                      prizes,
                      usr,
                      fixtures,
                      similar,
                      standings,
                      standGroups,
                      poolWeeks,
                      tickets_
                    ]
                    try {
                      utils.checkAxiosAllErrors(responses)
                    } catch (error) {
                      console.error(error)
                      Sentry.captureException(error)
                    }
  
                    poolI_ = individual.data
                    premios_ = prizes.data
                    user_ = usr.data
                    fixtures_ = fixtures.data.fixtures
                    parley_ = fixtures.data.parlay_blocks
                    parleyDeadlines_ = fixtures.data.parlay_deadlines
                    firstFixture_ = fixtures.data.first
                    similar_ = similar.data
                    standings_ = standings.data
                    standGroups_ = standGroups.data
                    poolWeeks_ = poolWeeks.data
                    currentWeek_ = poolWeeks.data.current
                    tickets_ = tickets_.data.tickets
  
                    this.setState(
                      () => {
                        const tempProps = []
                        /**
                           * List of individual Props to handle in main state
                           */
                        fixtures_.forEach((fixture) => {
                          if (poolI_.pool.format === 'mixed' && fixture.props) {
                            fixture.props.map((prop) => {
                              const tempObj = prop
                              tempObj.fixture = {
                                id: fixture.id,
                                sort_status: fixture.sort_status,
                                time_left: fixture.time_left,
                                enabled: fixture.enabled,
                                deadline: fixture.deadline,
                                suspended: fixture.suspended,
                                home_team: {
                                  id: fixture.home_team_id,
                                  abbreviation: fixture.home_team.abbreviation
                                },
                                away_team: {
                                  id: fixture.away_team_id,
                                  abbreviation: fixture.away_team.abbreviation
                                }
                              }
                              tempObj.enabled = fixture.enabled
                              tempProps.push(tempObj)
                            })
                          }
                        })
  
                        if (poolI_.pool.status === 'live' && this.queryParams.order !== 'nick') {
                          if (poolI_.parlay_flag === false) {
                            console.log('First  sort', standings_.items.length)
                            standings_?.items?.sort(
                              firstBy('total_possibly_correct', -1).thenBy('entry_id')
                            )
                          } else {
                            standings_?.items?.sort(
                              firstBy('total_possibly_correct', -1)
                                .thenBy('parlay_elimination')
                                .thenBy('entry_id')
                            )
                          }
                        } else if (poolI_.pool.closed) {
                          if (poolI_.parlay_flag === false) {
                            console.log('default sort closed wothout entry  ')
                            if (standings_.items && standings_?.items?.sort !== null) {
                              standings_?.items?.sort(firstBy('rank', 1).thenBy('entry_id'))
                            }
                          }
                        }
  
                        const balanceEnough = utils.isBalanceEnough(
                          poolI_.pool.entry_currency,
                          poolI_.pool.entry_fee
                        )

                        return {
                          // entries: [],
                          // fixtureProps: tempProps,
                          // selectedEntry: null,
                          // withRegister: false,
                          ticketsAvailable: tickets_,
                          fixtures: fixtures_,
                          parley: parley_,
                          parleyDeadlines: parleyDeadlines_,
                          firstFixture: firstFixture_,
                          poolInfo: poolI_,
                          enoughBalance: !(!balanceEnough && !tickets_.length),
                          poolWeeks: poolWeeks_,
                          poolWeekSelected: this.state.poolWeekSelected,
                          premios: premios_,
                          loadedPicks: true,
                          quinielaID: quinaID,
                          selectedEntryData: null,
                          similarPools: similar_,
                          standGroups: standGroups_,
                          defaultStandings: standings_,
                          standings: standings_,
                          userData: user_
                        }
                      },
                      () => {
                        if (poolI_.pool.status === 'live') {
                          const temporalStandings = this.state.standings
                          const updatedPositions = this.updateStandingsAganar(
                            this.state.standings.items,
                            this.state.poolInfo.pool.prize_currency
                          )
                          temporalStandings.items = updatedPositions
                          this.setState({
                            standings: temporalStandings
                          })
                        }
                      }
                    )
                    this.setState(
                      () => {
                        return {
                          logged: utils.isLogged(),
                          loaded: true,
                          loadedPicks: true
                        }
                      },
                      () => {
                        const fechaAhora = Date.now()
                        const fechaDeadline = this.state.poolInfo.pool.deadline
                        const fechaLimite = moment(fechaDeadline).diff(fechaAhora)
                        if (fechaLimite < 0) {
                          this.quinielaLive()
                        }
                      }
                    )
                  }
                )
              )
              .catch((error) => {
                console.error(error)
                Sentry.captureMessage('Breaking Fetching Data')
                Sentry.captureException(error)
                this.setState({ redirectToLobby: true })
              })
          }
        }
      })
      .catch((error) => {
        console.error(error)
        Sentry.captureException(error)
        this.setState({ redirectToLobby: true })
      })
  }
  

  /** **********************
   * Render
   * **********************/
  render() {
    // console.log('QIrender ', this.state.poolInfo);
    let conditionalComponent;
    let statusQuiniela;
    if (this.state.poolInfo !== undefined && this.state.poolInfo !== null) {
      statusQuiniela = this.state.poolInfo.pool.closed
        ? '[Cerrada]'
        : this.state.poolInfo.pool.upcoming
          ? '[Próxima]'
          : '[En Vivo]';
    }
    if (this.state.redirectMisQuinielas) {
      conditionalComponent = (
        <Redirect
          push
          to={{
            pathname: '/mis_quinielas',
          }}
        />
      );
    } else if (this.state.redirectToLobby) {
      console.log('redirect Lobby from Quinielas');
      conditionalComponent = (
        <Redirect
          push
          to={{
            pathname: '/',
          }}
        />
      );
    } else if (this.state.redirectToSame) {
      console.log('redirect Lobby To Same Quiniela');
      conditionalComponent = (
        <Redirect
          push
          to={{
            pathname: `/quiniela/${this.state.poolInfo.pool.slug}/${this.state.poolInfo.pool.id}/${this.state.selectedEntry}`,
          }}
        />
      );
    } else if (this.state.redirectToQuinielaLobby) {
      console.log('redirect Lobby To Quiniela');
      let currentQuinaPath = window.location.pathname;
      let redirectQuinaPath = currentQuinaPath.slice(0, currentQuinaPath.lastIndexOf('/'));

      setTimeout(() => {
        window.location = redirectQuinaPath;
      }, 800);

      conditionalComponent = <Redirect push replace to={{ pathname: currentQuinaPath }} />;
    } else if (this.state.redirectEntry) {
      console.log('redirect Lobby from Quinielas');
      conditionalComponent = (
        <Redirect
          to={{
            pathname: `/quiniela/${this.state.poolInfo.pool.slug}/${this.state.poolInfo.pool.id}/${this.state.selectedEntryData.id}`,
          }}
        />
      );
    } else {
      this.state.loaded
        ? (conditionalComponent = (
          <PageWrapper>
            <UserDataContextConsumer>
              {({
                isLogged,
                userData,
                userBalance,
                updateUserInfo,
                updateUserBalance,
                handleTutorialStep,
              }) => {
                return (
                  <QuinielaDataProvider
                    handleTutorialStep={handleTutorialStep}
                    updateEntriesParent={this.updateEntriesParent}
                    activeSort={this.state.activeSort}
                    cambiarEntradas={this.state.cambiarEntradas}
                    enoughBalance={this.state.enoughBalance}
                    entriesInfo={this.state.allEntries}
                    fetchSearchableStandings={this.fetchSearchableStandings}
                    fixtureProps={this.state.fixtureProps}
                    fixtures={this.state.fixtures}
                    firstFixture={this.state.firstFixture}
                    nextPicks={this.state.nextPicks}
                    nextProps={this.state.nextProps}
                    premios={this.state.premios}
                    poolInfo={this.state.poolInfo}
                    poolWeeks={this.state.poolWeeks}
                    poolWeekSelected={this.state.poolWeekSelected}
                    popUpNewUsersStatus={this.state.popUpNewUsersStatus}
                    similarPools={this.state.similarPools}
                    selectedEntry={this.state.selectedEntry}
                    selectedEntryData={this.state.selectedEntryData}
                    standings={this.state.standings}
                    sortChanged={this.state.sortChanged}
                    submittingSearch={this.state.submittingSearch}
                    queryParams={this.queryParams}
                    updateAfterRegister={this.updateAfterRegister}
                    updateUserInfo={updateUserInfo}
                    updateClosedFixture={this.updateClosedFixture}
                    updateStartedFixture={this.updateStrtedFixture}
                    updateScrollPositions={this.updateScrollPostions}
                    userData={userData}
                    withRegister={this.state.withRegister}
                  >
                    <div className="container">
                      <PanelShowEntryGift
                        show={this.state.showEntryGift}
                        entryGiftPopUp={this.state.entryGiftPopUp}
                        closePanel={this.closeEntryGift}
                        title="Te regalamos una entrada"
                      />
                      <PanelShowPopUpDeposit
                        show={this.state.showPopUpDeposit}
                        closePanel={this.closePopUpDeposit}
                        title="Bono por depósito"
                      />
                      <InvitarAmigos
                        pool={this.state.poolInfo}
                        showPanel={this.state.invitarAmigosMail}
                        userDataProps={userData}
                        updateStateFunc={this.showSendMail}
                      />
                      <Modal
                        dialogClassName="invitarAmigosModalDialog modal-dialog-xxxs"
                        className="invitarAmigosModal"
                        backdropClassName="invitarAmigosBackdrop"
                        show={this.state.createdPrivate}
                        onHide={() => {
                          this.hideInviteFriends();
                        }}
                      >
                        <PanelInvitarAmigos
                          whatsAppInvitation={this.whatsAppInvitation}
                          title={`Invita a tus amigos a jugar en Pickwin`}
                          pool={this.state.poolInfo.pool}
                          branchLink={this.state.branchLink}
                          openInvitePanel={this.handleInvitarAmigosMail}
                          closePanel={this.hideInviteFriends}
                        />
                      </Modal>
                      <Modal
                        dialogClassName="invitarAmigosModalDialog modal-dialog-xxxs"
                        className="invitarAmigosModal"
                        backdropClassName="invitarAmigosBackdrop"
                        show={this.state.showInvitePanel}
                        onHide={this.hideInviteFriendsPanel}
                      >
                        <PanelInvitarAmigos
                          title={`Invita a tus amigos a jugar la quiniela ${this.state.poolInfo.pool.name_replaced}`}
                          pool={this.state.poolInfo.pool}
                          whatsAppInvitation={this.whatsAppInvitation}
                          openInvitePanel={this.showSendMail}
                          closePanel={this.hideInviteFriendsPanel}
                          branchLink={this.state.branchLink}
                        />
                      </Modal>
                      <MediaQuery query="(max-width: 659px)">
                        <ScoreMobile
                          changeSelectedEntry={this.changeSelectedEntry}
                          changeStatusPool={this.changeStatusPool}
                          entries={this.state.entries}
                          handleCancelPanel={this.handleCancelClick}
                          handleClickFirstRegister={this.handleClickFirstRegister}
                          handleClickReRegister={this.handleClickReRegister}
                          liveClosedFixtures={this.state.liveClosedFixtures}
                          withRegister={this.state.withRegister}
                          logged={isLogged}
                          poolInfo={this.state.poolInfo}
                          pools={this.state.pools}
                          popup={false}
                          quinaID={this.state.quinielaID}
                          showInvite={this.showInviteFriendsPanel}
                          hideInvite={this.hideInviteFriendsPanel}
                          redirectToLobby={this.redirectToLobby}
                          refreshAfterCancel={this.refreshAfterCancel}
                          selectedEntry={this.state.selectedEntry}
                          updateClosedQuiniela={this.updateClosedQuiniela}
                          updateStateFuncCancel={this.updatePanelCancelState}
                          updateUserInfo={updateUserInfo}
                          updateAfterRegister={this.updateAfterRegister}
                          updateBranchLink={this.updateBranchLink}
                          status={this.state.poolInfo.pool.status}
                        />
                      </MediaQuery>

                      <MediaQuery query="(min-width: 660px)">
                        <Score
                          changeSelectedEntry={this.changeSelectedEntry}
                          changeStatusPool={this.changeStatusPool}
                          entries={this.state.entries}
                          handleCancelPanel={this.handleCancelClick}
                          handleClickFirstRegister={this.handleClickFirstRegister}
                          handleClickReRegister={this.handleClickReRegister}
                          liveClosedFixtures={this.state.liveClosedFixtures}
                          withRegister={this.state.withRegister}
                          logged={isLogged}
                          poolInfo={this.state.poolInfo}
                          pools={this.state.pools}
                          popup={false}
                          quinaID={this.state.quinielaID}
                          redirectToLobby={this.redirectToLobby}
                          refreshAfterCancel={this.refreshAfterCancel}
                          selectedEntry={this.state.selectedEntry}
                          showInvite={this.showInviteFriendsPanel}
                          hideInvite={this.hideInviteFriendsPanel}
                          updateBranchLink={this.updateBranchLink}
                          updateAfterRegister={this.updateAfterRegister}
                          updateClosedQuiniela={this.updateClosedQuiniela}
                          updateStateFuncCancel={this.updatePanelCancelState}
                          updateUserInfo={updateUserInfo}
                          status={this.state.poolInfo.pool.status}
                        />
                      </MediaQuery>

                      {/* Esto se renderiza cuando la quiniela ya finalizo poolInfo?.pool?.status === 'closed' */}
                      {/* Los botones cambian de lugar, aqui aparecen si son mas de 4 jornadas */}
                      {/* && (this.state?.poolWeeks?.weeks > 4 || this.state.poolInfo?.pool?.status === 'closed') */}
                      {(this.state.poolInfo.pool.type === 'full') ? (
                        <WeeksControls
                          weeks={this.state.poolWeeks.weeks}
                          handleWeekChange={this.changeSelectedWeek}
                          selectedWeek={
                            !this.state.poolWeekSelected
                              ? this.state.poolWeeks.current.id
                              : this.state.poolWeekSelected
                          }
                        />
                      ) : null}
                      <QuinielaLobby
                        handleTutorialStep={handleTutorialStep}
                        updateCurrentEntry={this.state.updateCurrentEntry}
                        updateEntriesParent={this.updateEntriesParent}
                        activeSort={this.state.activeSort}
                        cambiarEntradas={this.state.cambiarEntradas}
                        changeSearchNick={this.changeSearchNick}
                        changeSelectedEntry={this.changeSelectedEntry}
                        changeSelectedWeek={this.changeSelectedWeek}
                        changeStatusPool={this.changeStatusPool}
                        closeFirstRegister={this.closeFirstRegister}
                        deleteNewUserStatus={this.deleteNewUserStatus}
                        desempates={this.state.poolInfo}
                        enoughBalance={this.state.enoughBalance}
                        entriesInfo={this.state.allEntries}
                        estado={this.state}
                        fetchSearchableStandings={this.fetchSearchableStandings}
                        fixtureProps={this.state.fixtureProps}
                        fixtures={this.state.fixtures}
                        parley={this.state.parley}
                        parleyDeadlines={this.state.parleyDeadlines}
                        firstFixture={this.state.firstFixture}
                        handleClickFirstRegister={this.handleClickFirstRegister}
                        handleUpdateReset={this.handleUpdateReset}
                        liveClosedFixtures={this.state.liveClosedFixtures}
                        liveFixturesData={this.state.liveFixturesData}
                        livePropsData={this.state.livePropsData}
                        loadedPicks={this.state.loadedPicks}
                        nextPicks={this.state.nextPicks}
                        nextProps={this.state.nextProps}
                        poolInfo={this.state.poolInfo}
                        poolWeeks={this.state.poolWeeks}
                        poolWeekSelected={this.state.poolWeekSelected}
                        popUpNewUsers={this.state.popUpNewUsers}
                        popUpNewUsersStatus={this.state.popUpNewUsersStatus}
                        queryParams={this.queryParams}
                        quinielaLive={this.quinielaLive}
                        refreshAfterGuardar={this.refreshAfterGuardar}
                        resetNickValue={this.resetNickValue}
                        resetPosiciones={this.resetPosiciones}
                        selectedEntry={this.state.selectedEntry}
                        selectedEntryData={this.state.selectedEntryData}
                        showPanelFirstRegister={this.state.showPanelFirstRegister}
                        showPanelReRegister={this.state.showPanelReRegister}
                        similarPools={this.state.similarPools}
                        sortChanged={this.state.sortChanged}
                        sortPositionList={this.sortPositionList}
                        standings={this.state.standings}
                        status={this.state.poolInfo.pool.status}
                        submittingSearch={this.state.submittingSearch}
                        ticketsAvailable={this.state.ticketsAvailable}
                        updateClosedFixture={this.updateClosedFixture}
                        updatedStandings={this.state.updatedStandings}
                        updateFixtures={this.updateFixtures}
                        updatePanelFirstRegister={this.updatePanelFirstRegister}
                        updatePanelReRegister={this.updatePanelReRegister}
                        updateAfterRegister={this.updateAfterRegister}
                        updateScrollPostions={this.updateScrollPositions}
                        updateStartedFixture={this.updateStartedFixture}
                        updateUserInfo={updateUserInfo}
                        userData={userData}
                        userBalance={userBalance}
                        updateUserBalance={updateUserBalance}
                        withRegister={this.state.withRegister}
                        reiniciarCambioDeEntrada={this.reiniciarCambioDeEntrada}
                        onRefreshData={this.refreshData}

                        // Prop drilling <WeeksControls />
                        weeks={this.state.poolWeeks.weeks}
                        handleWeekChange={this.changeSelectedWeek}
                        selectedWeek={
                          !this.state.poolWeekSelected
                            ? this.state.poolWeeks.current.id
                            : this.state.poolWeekSelected
                        }
                      />
                      <CancelRegister
                        // payType={this.state.itemSelected === null ? null: this.state.itemSelected[0].entry_currency}
                        allEntries={this.state.allEntries}
                        entries={this.state.entries}
                        logged={isLogged}
                        pool={this.state.poolInfo.pool}
                        pools={null}
                        quinaID={this.state.poolInfo.pool.id}
                        refreshAfterCancel={this.refreshAfterCancel}
                        redirectAfterCancel={this.redirectAfterCancel}
                        redirectToLobby={this.redirectToLobby}
                        registerPicks={this.state.registerPicks}
                        registerState={this.registerState}
                        showPanel={this.state.showPanelCancel}
                        updateStateFunc={this.handleCancelClick}
                        updateUserInfo={updateUserInfo}
                        userDataProps={userData}
                      />
                    </div>
                  </QuinielaDataProvider>
                );
              }}
            </UserDataContextConsumer>
          </PageWrapper>
        ))
        : (conditionalComponent = <Loading label={'Espera un momento porfavor...'} />);
    }
    return conditionalComponent;
  }
}
export default Quinielas;
